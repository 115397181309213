import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { ClubcardData } from 'apiHelpers/clubcard/getClubcardDataFromQuote';
import { isQuoteFromAggs } from 'helpers/productHelpers';
import {
  ClubcardLookup,
  ClubcardSearch,
  CustomerDetails,
  initialClubcardLookup,
  initialEmployeeNumber,
} from 'state/formData/customerDetails';
import mapAddress from './mapAddress';
import { QuoteCustomerInfo, QuotePolicyInfo } from '../quoteRequest';

const mapClubcardDataToForm = (
  customerInfo: QuoteCustomerInfo,
  clubcardData: ClubcardData
): Pick<
  CustomerDetails,
  | 'clubcardLookup'
  | 'clubcardSearch'
  | 'hasClubcard'
  | 'clubCardApplied'
  | 'hasClubCardUpdatedInQS'
> => {
  const { clubcardLookupResponse, clubcardSearchResponse } = clubcardData;
  const { isStaff, clubcardNumber } = customerInfo;

  // The search is always populated for a successful match even if a successful lookup was performed later
  // (This comes from an edge case where a lookup was performed before editing details to a valid match)
  const clubcardSearch: ClubcardSearch = clubcardSearchResponse
    ? {
        status: 'SUCCESS',
        data: clubcardSearchResponse,
      }
    : { status: 'FAILURE' };

  const clubCardApplied = clubcardSearch.status === 'SUCCESS' ? 'yes' : '';
  const hasClubCardUpdatedInQS = false;
  // If the search matches the quote clubcard number we assume the card was matched using search
  // and don't populate the lookup
  const useLookup =
    !clubcardSearchResponse || clubcardSearchResponse.clubcardNumber !== clubcardNumber;

  const clubcardLookup: ClubcardLookup =
    clubcardNumber && useLookup
      ? {
          lookupKey: clubcardNumber,
          // If the responses weren't successful, we assume that they did a lookup,
          // and we use the value they chose for 'isStaff'. This is a safe approximation
          // of what would have been returned from the API - i.e. we never call them
          // staff when they're not, but we may say they're not staff when they actually
          // are. We default to this because we don't want the loading to fail if the
          // clubcard APIs are down (although the quote API calls may still then
          // fail...).
          data: clubcardLookupResponse || { isStaff: !!isStaff },
          status: LookupStatus.Success,
        }
      : initialClubcardLookup;
  let hasClubcard = '';
  if (!isQuoteFromAggs) {
    hasClubcard = clubcardSearch.status === 'SUCCESS' ? 'yes' : 'no';
  } else {
    hasClubcard =
      clubcardSearch.status === 'SUCCESS' || clubcardLookup.data ? 'yes' : 'askLater';
  }

  return {
    clubcardSearch,
    clubcardLookup,
    hasClubcard,
    clubCardApplied,
    hasClubCardUpdatedInQS,
  };
};

const mapCustomerDetails = (
  customerInfo: QuoteCustomerInfo,
  policyInfo: QuotePolicyInfo,
  clubcardData: ClubcardData
): CustomerDetails => {
  return {
    customerFirstName: customerInfo.firstName,
    customerLastName: customerInfo.lastName,
    customerTelephone: customerInfo.contactPhoneNumber,
    customerEmail: customerInfo.email,
    customerDob: dateToDateValue(new Date(customerInfo.dob)),
    customerTitle: customerInfo.title,
    customerGender: customerInfo.gender,
    numberOfPetsInHousehold: policyInfo.numberPetInHousehold,
    employeeNumber: customerInfo.staffId
      ? { status: LookupStatus.Success, number: customerInfo.staffId }
      : initialEmployeeNumber,
    // If the user matched a card and then selected no we want to keep that selection.
    // Otherwise either the user selected yes in which case we return true
    // or the user didn't reach the 'are you staff' question and we return true in order
    // to have yes as the default value if the question is displayed as part of aggs quotes
    hasColleagueClubcard: !customerInfo.clubcardNumber || customerInfo.isStaff !== false,
    ...mapAddress(customerInfo.address),
    ...mapClubcardDataToForm(customerInfo, clubcardData),
  };
};

export default mapCustomerDetails;
