import WhyChooseUs from '@rsa-digital/evo-shared-components/components/WhyChooseUs';
import {
  Explanation,
  ReasonHeading,
  ReasonWrapper,
  SectionHeading,
} from '@rsa-digital/evo-shared-components/components/WhyChooseUs/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const WhyChooseUsGrid = styled(WhyChooseUs)`
  && {
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(3)};

    ${mediaQuery.tabletLandscape`
      margin-top: 0;
      margin-bottom: 0;
  `}
  }

  ${SectionHeading} {
    display: ${({ title }) => (title ? 'block' : 'none')};
    margin-bottom: ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(4)};
    `}
  }

  ${ReasonWrapper} {
    margin-bottom: ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(4)};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${ReasonHeading} {
    align-self: center;

    ${mediaQuery.tabletPortrait`
      align-self: auto;
    `}
  }

  ${Explanation} {
    text-align: center;
    ${fonts.paragraphLarge}

    ${mediaQuery.tabletPortrait`
      text-align: left;
    `}
  }
`;
