import getClubcardDataFromQuote from 'apiHelpers/clubcard/getClubcardDataFromQuote';
import {
  getQuoteOptionsFromCoverLevel,
  getQuoteOptionsFromQuote,
} from 'apiHelpers/quote/bundleCoverMapping';
import { mapFormToRequote } from 'apiHelpers/quote/mapFormToQuote';
import mapQuoteToForm from 'apiHelpers/quote/mapQuoteToForm';
import { Quote } from 'apiHelpers/quote/quoteResponse';
import quoteClient from 'apiHelpers/quoteClient';
import { Dispatch, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CoverLevel } from 'helpers/businessConstants';
import {
  ASSUMPTIONS_AGREEMENT_SESSION_KEY,
  retrieveData,
} from 'helpers/sessionStorageHelpers';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import {
  AssumptionsAgreement,
  UPDATE_ASSUMPTIONS_AGREEMENT,
  UpdateAssumptionsAgreementAction,
} from 'state/formData/assumptionsAgreement';
import {
  UPDATE_QUOTE_IN_PROGRESS,
  UpdateQuoteInProgressAction,
} from 'state/formData/quoteInProgress';
import {
  UPDATE_QUOTE_OPTIONS,
  UpdateQuoteOptionsAction,
} from 'state/formData/quoteOptions';
import { UPDATE_QUOTE, UpdateQuoteAction } from './quote';

export const useInitialiseQuote = (): ((
  quote: Quote,
  setQuoteOptionsFromQuote?: boolean
) => Promise<void>) => {
  const dispatch = useDispatch<
    Dispatch<
      | UpdateQuoteAction
      | UpdateQuoteOptionsAction
      | UpdateQuoteInProgressAction
      | UpdateAssumptionsAgreementAction
    >
  >();

  const loadAssumptionsAgreement = useCallback((): void => {
    const storedAssumptionsAgreement = retrieveData(ASSUMPTIONS_AGREEMENT_SESSION_KEY);

    if (storedAssumptionsAgreement) {
      const parsedAssumptionsAgreement: AssumptionsAgreement = JSON.parse(
        storedAssumptionsAgreement
      );
      dispatch({
        type: UPDATE_ASSUMPTIONS_AGREEMENT,
        update: parsedAssumptionsAgreement,
      });
    }
  }, [dispatch]);

  const disableDateChecks = useDisableDateChecks();

  return async (quote, setQuoteOptionsFromQuote) => {
    const clubcardData = await getClubcardDataFromQuote(quote);
    // ----------------------------------------------------------------------------------------
    // TODO: EP-666 & EP-667 - remove this hack/workaround - we should just use the quote passed
    // in directly.
    // After scheduling payment, the quote seems to get messed up with both:
    // a) the covers included and
    // b) the instalment value changing to be the annual price, if scheduled annually.
    // We tried to fix this properly by adapting what was sent to the backend - see commit
    // 88aae88fda28e1f0d83f2e68e1155b90e8243792 - but that introduced a pricing bug where
    // (specifically accident only) quotes were doubling in price when payment was scheduled.
    // (It seems very possible that is an AIS bug since the rest of the quote remained the same.)
    // As a TEMPORARY solution we are requoting (with the covers from the selected bundle) every
    // time we retrieve the quote which 'fixes' it because the covers and instalments get reset.
    const formData = mapQuoteToForm(quote, clubcardData);
    const requoteRequest = mapFormToRequote(
      formData.petsDetails,
      formData.customerDetails,
      formData.jointPolicyholderDetails,
      formData.policyDetails,
      disableDateChecks,
      quote
    );
    const requotedRetrievedQuote = await quoteClient.requote(requoteRequest);
    // ----------------------------------------------------------------------------------------
    dispatch({ type: UPDATE_QUOTE, quote: requotedRetrievedQuote });
    dispatch({
      type: UPDATE_QUOTE_IN_PROGRESS,
      quote: mapQuoteToForm(requotedRetrievedQuote, clubcardData),
    });
    if (setQuoteOptionsFromQuote) {
      dispatch({
        type: UPDATE_QUOTE_OPTIONS,
        update: getQuoteOptionsFromQuote(requotedRetrievedQuote),
      });
    }
    loadAssumptionsAgreement();
  };
};

export const useInitialiseQuoteWithCoverLevel = (): ((
  quote: Quote,
  coverLevel: CoverLevel
) => Promise<void>) => {
  const dispatch = useDispatch<Dispatch<UpdateQuoteOptionsAction>>();
  const initialiseQuote = useInitialiseQuote();

  return async (quote, coverLevel) => {
    await initialiseQuote(quote, false);
    dispatch({
      type: UPDATE_QUOTE_OPTIONS,
      update: getQuoteOptionsFromCoverLevel(coverLevel),
    });
  };
};
