import { getStartOfDay } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { AccidentAndIllnessCoverDuration } from 'state/formData/quoteOptions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import { CsAsset, CsOffer, CsPromoCodeOffer } from 'types/contentStack';
import { ProductId } from './businessConstants';
import { processImageAsset } from './csTypeProcessors';
import { Product } from './productHelpers';
import { petType_CAT, petType_DOG } from './referenceDataConstants';
import { CurrentQuote, useCurrentQuote } from './useCurrentQuote';

export enum ActiveProductId {
  Direct = 'Direct',
  GoCompare = 'GoCompare',
  CTM = 'Compare The Market',
  MSM = 'Money Super Market',
}

type CsPetOfferPanel = {
  csPetOffers: {
    price_panel_offers: CsOffer[];
    quote_confirmation_offers: CsOffer[];
    promocode_offers: CsPromoCodeOffer[];
  };
};

const query = graphql`
  query {
    csPetOffers {
      price_panel_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_types
        offer_start_date
        offer_end_date
        promocode
      }
      quote_confirmation_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_types
        offer_start_date
        offer_end_date
        promocode
      }
      promocode_offers {
        notqualified_multipet_text
        notqualified_singlepet_text
        promocode
        qualified_multipet_suffix_text
        qualified_prefix_text
        qualified_singlepet_suffix_text
      }
    }
  }
`;

export const displayClubcardDiscount = (quote: CurrentQuote): boolean => {
  return !!quote.customerInfo?.clubcardNumber;
};

export const hasStaffDiscount = (quote: CurrentQuote): boolean =>
  !!quote.customerInfo?.isStaff;

export const displayMultipetDiscount = (quote: CurrentQuote): boolean =>
  (quote.petInfos?.length || 0) > 1;

const useQuery = (): CsPetOfferPanel => useStaticQuery<CsPetOfferPanel>(query);

const getProductType = (quote: CurrentQuote): Product | null => {
  switch (quote.quoteOptions.accidentAndIllnessCoverDuration) {
    case AccidentAndIllnessCoverDuration.Short_Term:
      return Product.Standard;
    case AccidentAndIllnessCoverDuration.Until_Limit:
      return Product.Extra;
    case AccidentAndIllnessCoverDuration.Ongoing:
      return Product.Premier;
    default:
      return null;
  }
};

export const isOfferValidForProductId = (
  productId: ProductId,
  activeProductIds: ActiveProductId[]
): boolean => {
  switch (productId) {
    case ProductId.DIRECT:
      return activeProductIds.includes(ActiveProductId.Direct);
    case ProductId.GO_COMPARE:
      return activeProductIds.includes(ActiveProductId.GoCompare);
    case ProductId.COMPARE_THE_MARKET:
      return activeProductIds.includes(ActiveProductId.CTM);
    case ProductId.MONEY_SUPERMARKET:
      return activeProductIds.includes(ActiveProductId.MSM);
    default:
      return false;
  }
};

export const isCoverLevelActive = (
  productType: Product,
  activeCoverLevels: Product[]
): boolean => {
  switch (productType) {
    case Product.Standard:
      return activeCoverLevels.includes(Product.Standard);
    case Product.Extra:
      return activeCoverLevels.includes(Product.Extra);
    case Product.Premier:
      return activeCoverLevels.includes(Product.Premier);
    default:
      return false;
  }
};

// These string constants ('Dog' and 'Cat') correspond to the values set
// in Contentstack for active pet types in offers:
export enum ActivePetType {
  DOG = 'Dog',
  CAT = 'Cat',
}

export const isAnyActivePetTypeInQuote = (
  currentQuote: CurrentQuote,
  offerActivePetTypes: ActivePetType[]
): boolean => {
  const quotePetTypesSet = new Set(
    currentQuote?.petInfos?.map((petInfo) => {
      switch (petInfo.petType) {
        case petType_CAT:
          return ActivePetType.CAT;
        case petType_DOG:
          return ActivePetType.DOG;
        default:
          return '';
      }
    })
  );

  return offerActivePetTypes.some((activePetType) => quotePetTypesSet.has(activePetType));
};

export const isValidPromocode = (
  quote: CurrentQuote,
  promoCode: string | undefined
): boolean => {
  if (quote?.policyInfo?.promotionalCode === '' || !promoCode) return true;
  return quote?.policyInfo?.promotionalCode === promoCode;
};

export const offerIsInActiveRange = (
  offerStartDate: string | undefined,
  offerEndDate: string | undefined
): boolean => {
  const startOfToday = getStartOfDay(new Date());

  const offerStarted = !offerStartDate || startOfToday >= new Date(offerStartDate);
  const offerNotExpired = !offerEndDate || startOfToday <= new Date(offerEndDate);

  return offerStarted && offerNotExpired;
};

export const useValidPricePanelOffers = (): CsOffer[] | undefined => {
  const {
    csPetOffers: { price_panel_offers, promocode_offers },
  } = useQuery();
  const quote = useCurrentQuote();

  const offers = price_panel_offers
    .filter(
      ({
        active_routes_to_quote,
        active_cover_levels,
        active_pet_types,
        offer_start_date,
        offer_end_date,
        promocode,
      }) =>
        isOfferValidForProductId(quote.productId, active_routes_to_quote) &&
        isCoverLevelActive(getProductType(quote) as Product, active_cover_levels) &&
        isValidPromocode(quote, promocode) &&
        offerIsInActiveRange(offer_start_date, offer_end_date) &&
        isAnyActivePetTypeInQuote(quote, active_pet_types)
    )
    .map(
      (pricePanelOffer: CsOffer): CsOffer => {
        if (pricePanelOffer.promocode === '')
          return { ...pricePanelOffer, is_clubcard_valid: true };

        promocode_offers
          .filter(
            (promocodeOffer: CsPromoCodeOffer) =>
              promocodeOffer.promocode?.toLowerCase() ===
              pricePanelOffer.promocode?.toLowerCase()
          )
          .forEach((promocodeOffer: CsPromoCodeOffer) => {
            const isMultiPet = (quote?.petInfos?.length || 0) > 1;
            const isValidClubcard = displayClubcardDiscount(quote);
            // eslint-disable-next-line no-param-reassign
            pricePanelOffer.is_clubcard_valid = isValidClubcard;
            if (isValidClubcard) {
              if (isMultiPet) {
                // eslint-disable-next-line no-param-reassign
                pricePanelOffer.content_rich_text = `${promocodeOffer.qualified_prefix_text} ${promocodeOffer.qualified_multipet_suffix_text}`;
              }
              if (!isMultiPet) {
                // eslint-disable-next-line no-param-reassign
                pricePanelOffer.content_rich_text = `${promocodeOffer.qualified_prefix_text} ${promocodeOffer.qualified_singlepet_suffix_text}`;
              }
            }

            return pricePanelOffer;
          });
        return pricePanelOffer;
      }
    );

  return offers ?? undefined;
};

export const useValidConfirmationOffers = (): CsOffer[] | undefined => {
  const {
    csPetOffers: { quote_confirmation_offers },
  } = useQuery();
  const [quote] = useConfirmationQuote();

  if (quote) {
    const offers = quote_confirmation_offers.filter(
      ({
        active_routes_to_quote,
        active_cover_levels,
        active_pet_types,
        offer_start_date,
        offer_end_date,
        promocode,
      }) =>
        isOfferValidForProductId(quote.productId, active_routes_to_quote) &&
        isCoverLevelActive(getProductType(quote) as Product, active_cover_levels) &&
        isValidPromocode(quote, promocode) &&
        offerIsInActiveRange(offer_start_date, offer_end_date) &&
        isAnyActivePetTypeInQuote(quote, active_pet_types)
    );

    return offers ?? undefined;
  }

  return undefined;
};

export const useValidPromocodeOffers = (): CsOffer[] | undefined => {
  const {
    csPetOffers: { price_panel_offers },
  } = useQuery();
  const [quote] = useConfirmationQuote();

  if (quote) {
    const validPromocodeOffers = price_panel_offers.filter(
      ({
        active_routes_to_quote,
        active_cover_levels,
        offer_start_date,
        offer_end_date,
        promocode,
      }) =>
        isOfferValidForProductId(quote.productId, active_routes_to_quote) &&
        isCoverLevelActive(getProductType(quote) as Product, active_cover_levels) &&
        isValidPromocode(quote, promocode) &&
        offerIsInActiveRange(offer_start_date, offer_end_date)
    );

    return validPromocodeOffers ?? undefined;
  }

  return undefined;
};

// Tesco do not want this for now, but may in future - current agreed upon approach is to have it show if an icon is found in CS, see EP-514 for details
export const displayOnlineDiscount = (onlineDiscountIcon: CsAsset | null): boolean => {
  const onlineDiscountLogo = processImageAsset(onlineDiscountIcon);
  return onlineDiscountLogo !== undefined;
};

export const displayPromocodeTerms = (quote: CurrentQuote): boolean => {
  return !!quote?.policyInfo?.promotionalCode;
};
