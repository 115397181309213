import { isQuoteFromAggs } from 'helpers/productHelpers';
import { CustomerDetails } from 'state/formData/customerDetails';
import { JointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { PetsDetails } from 'state/formData/petsDetails';
import { PolicyDetails } from 'state/formData/policyDetails';
import {
  generateRequoteParameters,
  getQuoteOptionsFromQuote,
} from './bundleCoverMapping';
import mapCustomerInfo from './formToQuoteMappings/mapCustomerInfo';
import mapPetsInfo from './formToQuoteMappings/mapPetsInfo';
import mapPolicyInfo from './formToQuoteMappings/mapPolicyInfo';
import { QuoteRequest, RequoteRequest } from './quoteRequest';
import { Quote } from './quoteResponse';

const mapFormToQuote = (
  petsDetails: PetsDetails,
  customerDetails: CustomerDetails,
  jointPolicyholderDetails: JointPolicyholderDetails,
  policyDetails: PolicyDetails,
  disableDateChecks: boolean,
  storedQuote?: Quote
): QuoteRequest => ({
  petInfos: mapPetsInfo(petsDetails, new Date(policyDetails.coverStartDate), storedQuote),
  customerInfo: mapCustomerInfo(customerDetails, isQuoteFromAggs(storedQuote)),
  policyInfo: {
    ...mapPolicyInfo(
      customerDetails,
      policyDetails,
      jointPolicyholderDetails,
      storedQuote?.policyInfo
    ),
    disableDateChecks,
  },
});

export const mapFormToRequote = (
  petsDetails: PetsDetails,
  customerDetails: CustomerDetails,
  jointPolicyholderDetails: JointPolicyholderDetails,
  policyDetails: PolicyDetails,
  disableDateChecks: boolean,
  storedQuote: Quote
): RequoteRequest => {
  const quoteOptions = getQuoteOptionsFromQuote(storedQuote);

  return {
    ...mapFormToQuote(
      petsDetails,
      customerDetails,
      jointPolicyholderDetails,
      policyDetails,
      disableDateChecks,
      storedQuote
    ),
    ...generateRequoteParameters(storedQuote, quoteOptions, policyDetails.coverStartDate),
  };
};

export default mapFormToQuote;
