import { useEffect, useState } from 'react';
import {
  displayClubcardDiscount,
  displayMultipetDiscount,
  hasStaffDiscount,
} from './discountHelpers';
import {
  getAggregatorFromProductId,
  getCoverTypeLabelFromCoverLevel,
  trackEvent,
  TrackingEvent,
} from './eventTracking';
import { getProductFromQuoteOptions, Product } from './productHelpers';
import {
  catBreed_MOGGY,
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_PEDIGREE,
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
  petType_CAT,
} from './referenceDataConstants';
import { CurrentQuote } from './useCurrentQuote';

const TAX_RATE = 20;
const DEFAULT_VET_FEE_COVER_LIMIT = '3000';

type EcommerceProduct = {
  id: string;
  name?: string; // cover type
  variant?: string;
  brand?: string;
  quantity?: number;
  price?: number; // annual price
  dimension18?: string; // monthly or annual
  dimension17?: string; // Pet type & index
  dimension13?: string; // vet fee limit
  dimension14?: string; // voluntary excess
  dimension16?: string; // start date
  dimension10?: string; // "Cat" or "Dog"
  dimension19?: string; // Aggregator name or "Direct"
  dimension20?: string; // breed type
  dimension21?: string; // breed
  dimension48?: string; // voluntary excess percent
  dimension56?: string; // pet count
  metric1?: '1'; // All modules are core for Tesco
};

type EcommerceEvent = TrackingEvent & {
  dimension4?: string; // aggregator label
  dimension5?: string; // quote reference
  dimension12?: string; // quote type ('Quote and Buy')
  metric2?: number; // monthly price (0 for annual)
  metric3?: number; // initial payment (first month or full annual)
  metric4?: number; // number of remaining installments (11 or 0)
  ecommerce?: {
    currencyCode: string;
    checkout?: {
      actionField: {
        step: number; // Step in journey
      };
      products: EcommerceProduct[];
    };
    purchase?: {
      actionField: {
        id: string; // Transaction ID
        affiliation?: string; // Product and purchase type
        revenue?: number; // Total revenue
        tax?: number; // Tax rate
        coupon?: string; // Discount code
      };
      products: EcommerceProduct[];
    };
  };
};

/* Covering each of these cases is not valuable */
/* istanbul ignore next */
export const getReadablePetBreedType = (breedType: string): string | undefined => {
  switch (breedType) {
    case dogBreedType_PEDIGREE:
    case catBreedType_PEDIGREE:
      return 'pedigree';
    case dogBreedType_CROSS_BREED:
      return 'cross-breed';
    case catBreedType_NON_PEDIGREE:
      return 'non-pedigree';
    case catBreed_MOGGY:
      return 'moggy';
    case mongrelSize_SMALL:
      return 'small mongrel';
    case mongrelSize_MEDIUM:
      return 'medium mongrel';
    case mongrelSize_LARGE:
      return 'large mongrel';
    default:
      return undefined;
  }
};

export const getVetFeeLimit = (quote: CurrentQuote): string | undefined => {
  const { extraCoverFeeLimit, premierCoverFeeLimit } = quote.quoteOptions;
  const product = getProductFromQuoteOptions(quote.quoteOptions);
  if (product === Product.Extra) {
    return extraCoverFeeLimit?.toString();
  }
  if (product === Product.Premier) {
    return premierCoverFeeLimit?.toString();
  }
  return DEFAULT_VET_FEE_COVER_LIMIT;
};

const getProducts = (quote: CurrentQuote | null): EcommerceProduct[] => {
  if (!quote) {
    return [];
  }

  let dogCount = 1;
  let catCount = 1;

  const getPetTypeIndex = (petType: string): string => {
    if (petType === petType_CAT) {
      const output = `C${catCount}`;
      catCount += 1;
      return output;
    }
    const outputString = `D${dogCount}`;
    dogCount += 1;
    return outputString;
  };

  const userHasSelectedCover = !!quote.petInfos?.[0].userSelectedCover;

  return (
    quote.petInfos?.map((pet, index) => {
      const product: EcommerceProduct = {
        id: 'Pet',
        variant: 'Core',
        brand: 'Tesco Pet',
        quantity: 1,
        dimension17: `Core - ${getPetTypeIndex(pet.petType)}`, // Pet type & index
        dimension16: quote.policyInfo?.coverStartDate, // start date
        dimension10: pet.petType === petType_CAT ? 'Cat' : 'Dog', // "Cat" or "Dog"
        dimension19: getAggregatorFromProductId(quote.productId) ?? 'Direct',
        dimension20: getReadablePetBreedType(pet.petBreedType), // breed type
        dimension21: pet.petBreed, // breed
        dimension56: `${quote.petInfos?.length}`,
        metric1: '1', // All modules are core for Tesco,
      };

      if (userHasSelectedCover) {
        product.name = getCoverTypeLabelFromCoverLevel(pet.coverLevelRequired); // cover type
        product.price = quote.price?.annualPrice.perPet?.[index]; // annual price
        product.dimension13 = getVetFeeLimit(quote); // vet fee limit
        product.dimension14 = pet.voluntaryExcessAmount; // voluntary excess
        product.dimension18 = quote.quoteOptions.isAnnualPayment
          ? 'Annualised'
          : 'Monthly'; // monthly or annual
        product.dimension48 = `${parseInt(pet.voluntaryExcessPercentage, 10)}`;
      }
      return product;
    }) || []
  );
};

type PaymentMetrics = {
  initialAmount: number;
  monthlyAmount: number;
  installments: number;
};

const getPaymentMetrics = (quote: CurrentQuote): PaymentMetrics => {
  if (quote.quoteOptions.isAnnualPayment) {
    return {
      monthlyAmount: 0,
      initialAmount: quote.price?.annualPrice.total ?? 0,
      installments: 1,
    };
  }
  return {
    monthlyAmount: quote.price?.monthlyPrice.total ?? 0,
    initialAmount: quote.price?.monthlyPrice.total ?? 0,
    installments: 11,
  };
};

const trackCheckoutEvent = (step: number, quote: CurrentQuote | null): void => {
  const event: EcommerceEvent = {
    event: 'eec.checkout',
    dimension12: 'Quote and Buy',
    ecommerce: {
      currencyCode: 'GBP',
      checkout: {
        actionField: {
          step,
        },
        products: getProducts(quote),
      },
    },
  };
  if (quote?.petInfos) {
    const { monthlyAmount, initialAmount, installments } = getPaymentMetrics(quote);

    event.dimension4 = getAggregatorFromProductId(quote?.productId);
    event.dimension5 = quote.policyInfo?.quoteNumber || undefined;
    event.metric2 = monthlyAmount;
    event.metric3 = initialAmount;
    event.metric4 = installments;
  }

  trackEvent(event);
};

const getDiscountList = (quote: CurrentQuote): string => {
  const promocode = quote.policyInfo?.promotionalCode;
  const discounts = [
    displayClubcardDiscount(quote) ? 'tesco clubcard' : null,
    hasStaffDiscount(quote) ? 'staff' : null,
    displayMultipetDiscount(quote) ? 'multipet' : null,
    promocode ? `promocode: ${promocode}` : null,
    'online',
  ];

  const activeDiscounts = discounts.filter((discount) => !!discount);

  return activeDiscounts.length > 0 ? activeDiscounts.join(', ') : 'not set';
};

export const trackPurchaseEvent = (quote: CurrentQuote): void => {
  const { monthlyAmount, initialAmount, installments } = getPaymentMetrics(quote);
  const event: EcommerceEvent = {
    event: 'eec.purchase',
    dimension4: getAggregatorFromProductId(quote.productId),
    dimension5: quote.policyInfo?.quoteNumber || undefined,
    dimension12: 'Quote and Buy',
    metric2: monthlyAmount,
    metric3: initialAmount,
    metric4: installments,
    ecommerce: {
      currencyCode: 'GBP',
      purchase: {
        actionField: {
          id: quote.policyInfo?.quoteNumber || '',
          affiliation: 'Pet - Quote and buy',
          revenue: quote.price?.annualPrice.total,
          tax: TAX_RATE,
          coupon: getDiscountList(quote),
        },
        products: getProducts(quote),
      },
    },
  };

  trackEvent(event);
};

// Hook to track once when the quote loads
export const useCheckoutTracking = (
  step: number,
  quote: CurrentQuote | null,
  requireQuote: boolean
): void => {
  const [tracked, setTracked] = useState(false);
  useEffect(() => {
    if (!tracked && (requireQuote ? !!quote?.customerInfo : true)) {
      trackCheckoutEvent(step, quote);
      setTracked(true);
    }
  }, [quote, requireQuote, step, tracked]);
};
