import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import React from 'react';
import Analytics from 'components/Analytics';
import ComplianceFooter from 'components/Footer/ComplianceFooter';
import HeroBanner from 'components/Header/HeroBanner';
import Meta from 'components/Meta';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import ReCaptcha from 'components/ReCaptcha';
import SessionExpiryWrapper, {
  SessionExpiryOption,
} from 'components/SessionExpiry/SessionExpiryWrapper';
import { PageTitle } from 'helpers/eventTracking';
import RefreshButton from './refresh';

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const Layout: React.FC<{
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
  introText?: string;
  introTextIsPii?: boolean;
  currentStep?: QuoteAndBuyStep;
  metaTitle: string;
  pageTitle: PageTitle;
  sessionExpiryOption?: SessionExpiryOption;
  isErrorPage?: boolean;
  children?: React.ReactNode;
}> = ({
  children,
  currentStep,
  heading,
  subheading,
  subheadingIsPii,
  introText,
  introTextIsPii,
  metaTitle,
  pageTitle,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
  isErrorPage,
}) => {
  return (
    <SessionExpiryWrapper pageTitle={pageTitle} sessionExpiryOption={sessionExpiryOption}>
      <ReCaptcha />
      {activeEnv !== 'test' && <Analytics />}
      {activeEnv === 'preview' && /* istanbul ignore next */ <RefreshButton />}
      <Meta metaTitle={metaTitle} />
      <GridContainer as="header">
        <HeroBanner
          heading={heading}
          subheading={subheading}
          subheadingIsPii={subheadingIsPii}
          introText={introText}
          introTextIsPii={introTextIsPii}
          pageTitle={pageTitle}
          currentStep={currentStep}
          isErrorPage={isErrorPage}
        />
      </GridContainer>
      <GridContainer as="main">
        {children}
        <GridContainer as="footer">
          <ComplianceFooter pageTitle={pageTitle} />
        </GridContainer>
      </GridContainer>
    </SessionExpiryWrapper>
  );
};

export default Layout;
