export const UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS =
  'UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS';
export const UPDATE_TESCO_MONTHLY_PAYMENT_DATE = 'UPDATE_TESCO_MONTHLY_PAYMENT_DATE';

export type UpdateTescoPageLoadTrackingStatusAction = {
  type: typeof UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS;
  pageLoadTracked: boolean;
};

export type UpdateTescoMonthlyPaymentDateAction = {
  type: typeof UPDATE_TESCO_MONTHLY_PAYMENT_DATE;
  monthlyPaymentDate: string;
};

export type TescoEventTrackingAction =
  | UpdateTescoPageLoadTrackingStatusAction
  | UpdateTescoMonthlyPaymentDateAction;
