import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { addItem } from 'helpers/arrayHelper';
import {
  catBreed_MOGGY,
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { Pet, PetsDetails, PetWithKey } from 'state/formData/petsDetails';
import { QuoteRequestPetInfo } from '../quoteRequest';

const mapPetsDetails = (petsInfo: QuoteRequestPetInfo[]): PetsDetails => {
  let petsDetails: PetWithKey[] = [];

  petsInfo
    .map((petInfo) => {
      const isCat = petInfo.petType === petType_CAT;
      const isDog = petInfo.petType === petType_DOG;
      const mongrelSizes = [mongrelSize_SMALL, mongrelSize_MEDIUM, mongrelSize_LARGE];
      const isMongrelDog = isDog && mongrelSizes.includes(petInfo.petBreedType);
      const isPedigreeDog = isDog && petInfo.petBreedType === dogBreedType_PEDIGREE;
      const isCrossBreedDog = isDog && petInfo.petBreedType === dogBreedType_CROSS_BREED;
      const isPedigreeCat = isCat && petInfo.petBreedType === catBreedType_PEDIGREE;
      const isNonPedigreeCat =
        isCat && petInfo.petBreedType === catBreedType_NON_PEDIGREE;

      const getDogBreedType = (): string => {
        if (isCat) {
          return '';
        }
        if (isMongrelDog) {
          return dogBreedType_MONGREL;
        }
        return petInfo.petBreedType;
      };

      return {
        petName: petInfo.petName,
        petType: petInfo.petType,
        dogBreedType: getDogBreedType(),
        dogPedigreeBreedName: !isPedigreeDog || isMongrelDog ? '' : petInfo.petBreed,
        dogCrossBreedName: isCrossBreedDog ? petInfo.petBreed : '',
        mongrelSize: isMongrelDog ? petInfo.petBreedType : '',
        petIsEligible: petInfo.isPetAtHomeAddress,
        catBreedType: isCat ? petInfo.petBreedType : '',
        catPedigreeBreedName: isPedigreeCat ? petInfo.petBreed : '',
        catNonPedigreeBreedName: isNonPedigreeCat ? petInfo.petBreed : '',
        catBreedUnknown: isCat ? petInfo.petBreed === catBreed_MOGGY : undefined,
        indoorCat: isCat ? petInfo.isIndoorPet : undefined,
        petGender: petInfo.petGender,
        petCost: petInfo.initialCostPound,
        petSpayed: petInfo.isPetSpayed,
        petChipped: petInfo.isPetChippedOrTagged,
        petInGoodHealth: !petInfo.isPetShowingIllness,
        petLivesWithYou: petInfo.petLivesWithYou,
        petForMakeMoney: petInfo.petForMakeMoney,
        dogBreedListLink: petInfo.dogBreedListLink,
        petDob: dateToDateValue(new Date(petInfo.dob)),
        petAge8ExcessAgreed: undefined,
      };
    })
    .forEach((pet: Pet) => {
      petsDetails = addItem(petsDetails, pet);
    });

  return petsDetails;
};

export default mapPetsDetails;
