import React from 'react';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const dataDomainScriptProduction = 'ea4715d9-dc4b-4673-bc26-675d2ccf3968';
const dataDomainScriptTest = 'ea4715d9-dc4b-4673-bc26-675d2ccf3968-test';

/* istanbul ignore next */
const oneTrust: JSX.Element[] = [
  <script
    src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
    type="text/javascript"
    data-domain-script={
      analyticsEnv === 'production' ? dataDomainScriptProduction : dataDomainScriptTest
    }
    key="oneTrustScripts"
  />,
  <script type="text/javascript" key="oneTrustWrapper">
    {'function OptanonWrapper() {}'}
  </script>,
];

export default oneTrust;
