import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import quoteClient from 'apiHelpers/quoteClient';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VoluntaryExcessAmount,
  VoluntaryExcessPercentage,
} from 'helpers/businessConstants';
import { getDateOnlyIsoString } from 'helpers/dateHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import useLoadingState from 'helpers/useLoadingState';
import { RootState } from 'state/createStore';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { UPDATE_QUOTE, UpdateQuoteAction } from 'state/quote/quote';
import {
  generateRequoteParameters,
  getCoverLevelFromQuoteOptions,
} from './bundleCoverMapping';
import { getPetInfosWithValidExcessPercentage } from './getPetInfosWithValidExcessPercentage';
import { QuoteRequestPetInfo, RequoteRequest } from './quoteRequest';

export type QuoteUpdaters = {
  saveQuoteOptions: (triggerEmail?: boolean) => Promise<void>;
  updatePetExcess: (
    petIndex: number,
    updatedExcess: VoluntaryExcessAmount
  ) => Promise<void>;
  updatePetExcessPercentage: (
    petIndex: number,
    updatedExcess: VoluntaryExcessPercentage
  ) => Promise<void>;
  isLoading: boolean;
} | null;

const useUpdateQuoteCover = (): QuoteUpdaters => {
  const storedQuote = useSelector((state: RootState) => state.quote);
  const quoteOptions = useSelector((state: RootState) => state.quoteSummaryOptions);
  const dispatch = useDispatch<Dispatch<UpdateQuoteAction>>();
  const { isLoading, withLoadingState } = useLoadingState();
  const disableDateChecks = useDisableDateChecks();
  const currentQuote = useCurrentQuote();
  const [customerDetails] = useCustomerDetails();
  const [policyDetails] = usePolicyDetails();
  const [jointPolicyholderDetails] = useJointPolicyholderDetails();

  if (!storedQuote) {
    return null;
  }

  const contactDetailsHaveBeenUpdated = (): boolean => {
    const oldContactEmail = currentQuote.customerInfo?.email;
    const oldContactTelephone = currentQuote.customerInfo?.contactPhoneNumber;
    return (
      oldContactEmail !== customerDetails.customerEmail ||
      oldContactTelephone !== customerDetails.customerTelephone
    );
  };

  const policyDateHasBeenUpdated = (): boolean => {
    const oldCoverStartDate = currentQuote.policyInfo?.coverStartDate;
    return oldCoverStartDate !== policyDetails.coverStartDate;
  };

  const jointPolicyHolderHasBeenUpdated = (): boolean => {
    const oldIncludeJoinPolicyHolder = currentQuote.policyInfo?.includeJoinPolicyHolder;
    const oldJointPolicyHolderTitle = currentQuote.policyInfo?.joinPolicyHolder?.title;
    const oldJointPolicyHolderFirstName =
      currentQuote.policyInfo?.joinPolicyHolder?.firstName;
    const oldJointPolicyHolderLastName =
      currentQuote.policyInfo?.joinPolicyHolder?.lastName;
    const oldJointPolicyHolderDob = currentQuote.policyInfo?.joinPolicyHolder?.dob;

    return (
      oldIncludeJoinPolicyHolder !== jointPolicyholderDetails.includeJointPolicyholder ||
      oldJointPolicyHolderTitle !== jointPolicyholderDetails.jointPolicyholderTitle ||
      oldJointPolicyHolderFirstName !==
        jointPolicyholderDetails.jointPolicyholderFirstName ||
      oldJointPolicyHolderLastName !==
        jointPolicyholderDetails.jointPolicyholderLastName ||
      oldJointPolicyHolderDob !==
        dateValueToISODateString(jointPolicyholderDetails.jointPolicyholderDob)
    );
  };

  const isUpdateRequired = (triggerEmail: boolean): boolean =>
    triggerEmail ||
    storedQuote.petInfos.some(
      (pet) => pet.coverLevelRequired !== getCoverLevelFromQuoteOptions(quoteOptions)
    ) ||
    contactDetailsHaveBeenUpdated() ||
    policyDateHasBeenUpdated() ||
    jointPolicyHolderHasBeenUpdated();

  const {
    jointPolicyholderTitle: title,
    jointPolicyholderFirstName: firstName,
    jointPolicyholderLastName: lastName,
    jointPolicyholderDob: dob,
  } = jointPolicyholderDetails;
  const dateString = dateValueToISODateString(dob);

  const updatedJointPolicyHolderDetails = {
    title,
    firstName,
    lastName,
    dob: dateString ? dateString.toString() : '',
  };

  const buildSaveQuoteOptionsRequest = (triggerEmail?: boolean): RequoteRequest => ({
    ...storedQuote,
    customerInfo: {
      ...storedQuote.customerInfo,
      email: customerDetails.customerEmail,
      contactPhoneNumber: customerDetails.customerTelephone,
    },
    policyInfo: {
      ...storedQuote.policyInfo,
      disableDateChecks,
      coverStartDate: getDateOnlyIsoString(policyDetails.coverStartDate),
      includeJoinPolicyHolder: jointPolicyholderDetails.includeJointPolicyholder,
      joinPolicyHolder: updatedJointPolicyHolderDetails,
    },
    ...generateRequoteParameters(storedQuote, quoteOptions),
    triggerCorrespondenceService: triggerEmail,
    petInfos: getPetInfosWithValidExcessPercentage(storedQuote.petInfos),
  });

  const performRequest = async (request: RequoteRequest): Promise<void> => {
    const quote = await withLoadingState(() => quoteClient.requote(request));
    dispatch({ type: UPDATE_QUOTE, quote });
  };

  const saveQuoteOptions = async (triggerEmail?: boolean): Promise<void> => {
    if (isUpdateRequired(!!triggerEmail)) {
      const request = buildSaveQuoteOptionsRequest(triggerEmail);
      await performRequest(request);
    }
  };

  const buildUpdatePetInfosRequest = (
    updatedPetInfos: QuoteRequestPetInfo[]
  ): RequoteRequest => ({
    ...storedQuote,
    policyInfo: { ...storedQuote.policyInfo, disableDateChecks },
    ...generateRequoteParameters(storedQuote, quoteOptions),
    petInfos: getPetInfosWithValidExcessPercentage(updatedPetInfos),
  });

  const updatePetExcess = async (
    petIndex: number,
    updatedExcess: VoluntaryExcessAmount
  ): Promise<void> => {
    const updatedPetInfos = storedQuote.petInfos.map((pet, index) => ({
      ...pet,
      voluntaryExcessAmount:
        index === petIndex ? updatedExcess : pet.voluntaryExcessAmount,
    }));
    const request = buildUpdatePetInfosRequest(updatedPetInfos);
    await performRequest(request);
  };

  const updatePetExcessPercentage = async (
    petIndex: number,
    updatedExcess: VoluntaryExcessPercentage
  ): Promise<void> => {
    const updatedPetInfos = storedQuote.petInfos.map((pet, index) => ({
      ...pet,
      voluntaryExcessPercentage:
        index === petIndex ? updatedExcess : pet.voluntaryExcessPercentage,
    }));
    const request = buildUpdatePetInfosRequest(updatedPetInfos);
    await performRequest(request);
  };

  return {
    saveQuoteOptions,
    updatePetExcess,
    updatePetExcessPercentage,
    isLoading,
  };
};

export default useUpdateQuoteCover;
