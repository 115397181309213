import GlobalStyle from '@rsa-digital/evo-shared-components/globalStyle';
import theme from '@rsa-digital/evo-shared-components/theme/Tesco';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { CsAsset } from 'types/contentStack';

type IconsData = {
  allCsIconSvgs: {
    nodes: {
      title: string;
      icon_code: string;
      filled_icon: CsAsset;
      outlined_icon: CsAsset;
    }[];
  };
};

const query = graphql`
  query {
    allCsIconSvgs {
      nodes {
        title
        icon_code
        filled_icon {
          filename
          localAsset {
            publicURL
          }
        }
        outlined_icon {
          localAsset {
            publicURL
          }
        }
      }
    }
  }
`;

const TescoGlobalStyle = createGlobalStyle`
  /*  We hide the overflow to remove the ability to scroll horizontally, which is induced by using 
      full-width components, such as the PageWidthContainer.
  */
  body {
    overflow-x: hidden;
  }
`;

const TescoThemeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const data = useStaticQuery<IconsData>(query);

  const icons = { ...theme.icons };
  data.allCsIconSvgs.nodes.forEach((icon) => {
    icons[icon.icon_code] = {
      default: icon.filled_icon?.localAsset?.publicURL,
      variant: icon.outlined_icon?.localAsset?.publicURL ?? undefined,
    };
  });

  const themeWithUpdatedIcons = { ...theme, icons };
  const themeWithUpdatedIconsAndAlternateMobileButtons = {
    ...themeWithUpdatedIcons,
    formLayout: { alternateMobileFooterButtons: false },
  };
  return (
    <ThemeProvider theme={themeWithUpdatedIconsAndAlternateMobileButtons}>
      <Helmet>
        <style>{theme.fontFaces}</style>
      </Helmet>
      <GlobalStyle />
      <TescoGlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default TescoThemeProvider;
