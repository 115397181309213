import {
  TescoEventTrackingAction,
  UPDATE_TESCO_MONTHLY_PAYMENT_DATE,
  UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS,
} from './actions';

type TescoEventTrackingState = {
  pageLoadTracked: boolean;
  monthlyPaymentDate: string;
};

const tescoEventTrackingReducer = (
  state: TescoEventTrackingState = {
    pageLoadTracked: false,
    monthlyPaymentDate: '1',
  },
  action: TescoEventTrackingAction
): TescoEventTrackingState => {
  switch (action.type) {
    case UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS:
      return {
        ...state,
        pageLoadTracked: action.pageLoadTracked,
      };
    case UPDATE_TESCO_MONTHLY_PAYMENT_DATE:
      return {
        ...state,
        monthlyPaymentDate: action.monthlyPaymentDate,
      };
    default:
      return state;
  }
};

export default tescoEventTrackingReducer;
