// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import { parseDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { getCoverLevelFromQuoteOptions } from 'apiHelpers/quote/bundleCoverMapping';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { petAgeIsEqualToTwoMonths } from 'businessLogic/aggregators';
import { useEffect, useState, version } from 'react';
import { useSelector } from 'react-redux';
import { getDifferenceInDates } from 'helpers/dateHelpers';
import { getReadablePetBreedType, getVetFeeLimit } from 'helpers/ecommerceTracking';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { RootState } from 'state/createStore';
import { CustomerDetails, useCustomerDetails } from 'state/formData/customerDetails';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { getFullYears } from './ageHelpers';
import { ProductId } from './businessConstants';
import {
  ANNUAL_PAYMENT_PSEUDO_URL,
  getAggregatorFromProductId,
  getAggregatorFromReferrer,
  getCoverTypeLabelFromCoverLevel,
  getReadableExcessAmount,
  MONTHLY_PAYMENT_PSEUDO_URL,
  trackEvent,
  TrackingEvent,
  userHasSelectedCover,
} from './eventTracking';
import {
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  petType_CAT,
} from './referenceDataConstants';
import { quoteAndBuyRoutes } from './routingHelper';
import { QUOTE_OPTIONS_SESSION_KEY, retrieveData } from './sessionStorageHelpers';
import { CurrentQuote, useCurrentQuote } from './useCurrentQuote';
import { useMetaTitle } from './useMetaTitle';

const quoteOptions: QuoteOptions = JSON.parse(
  retrieveData(QUOTE_OPTIONS_SESSION_KEY) || '{}'
);

export const getProductTier = (): string => {
  const coverTypeMapping = {
    standard: 'Short term',
    extra: 'Limit reached',
    premier: 'Ongoing',
  };
  const quote_Options: QuoteOptions = JSON.parse(
    retrieveData(QUOTE_OPTIONS_SESSION_KEY) || '{}'
  );
  return quote_Options.accidentAndIllnessCoverDuration
    ? coverTypeMapping[quote_Options.accidentAndIllnessCoverDuration]
    : 'none selected';
};

const isCrossbreedDog = (pet: QuoteResponsePetInfo): boolean =>
  pet.petBreedType === dogBreedType_CROSS_BREED ||
  pet.petBreedType === dogBreedType_MONGREL;

const getAdditionalQuestionsDisplayed = (
  currentQuote: CurrentQuote
): string | undefined => {
  if (currentQuote.productId !== ProductId.COMPARE_THE_MARKET) return undefined;

  const questions = [];

  if (currentQuote.petInfos?.some((pet) => petAgeIsEqualToTwoMonths(new Date(pet.dob))))
    questions.push('DOB');

  if (currentQuote.petInfos?.some((pet) => isCrossbreedDog(pet))) questions.push('Breed');

  return questions.length ? questions.join(', ') : undefined;
};

const getPetAge = (currentQuote: CurrentQuote): string | undefined => {
  if (currentQuote?.petInfos?.length) {
    const firstPet = currentQuote.petInfos[0];
    const petDobDate = parseDate(firstPet.dob);
    const petAge = getFullYears(petDobDate).toString();
    return petAge;
  }
  return undefined;
};

export const trackPageView = (
  path: string,
  pageTitle: string,
  quote: CurrentQuote,
  customerDetails: CustomerDetails
): void => {
  const getPath = (): string => {
    if (path === quoteAndBuyRoutes.payment) {
      return quoteOptions.isAnnualPayment
        ? ANNUAL_PAYMENT_PSEUDO_URL
        : MONTHLY_PAYMENT_PSEUDO_URL;
    }
    return path;
  };

  const getAggregatorId = (): string | undefined => {
    const aggregatorIdFromProductId = getAggregatorFromProductId(quote?.productId);
    const aggregatorIdFromReferrer = getAggregatorFromReferrer(document?.referrer);
    return aggregatorIdFromProductId ?? aggregatorIdFromReferrer;
  };

  const userHasClubcard =
    customerDetails.clubcardSearch.status === 'SUCCESS' ||
    customerDetails.clubcardLookup.status === LookupStatus.Success ||
    !!quote.customerInfo?.clubcardNumber;

  const event: TrackingEvent = {
    event: 'newPage',
    vpvPath: getPath(),
    pageTitle,
    siteVersion: version,
    additionalQuestionsDisplayed: getAdditionalQuestionsDisplayed(quote),
    quoteId: quote?.policyInfo?.quoteNumber || undefined,
    quoteAggregatorId: getAggregatorId(),
    policyStartDate: quote?.policyInfo?.coverStartDate,
    clubcardCustomer:
      customerDetails.clubcardSearch.status !== 'NONE' || quote.customerInfo
        ? userHasClubcard
        : undefined,
    promoCode: quote?.policyInfo?.promotionalCode,
    productName: getProductTier(),
    vetFeeLimit: quoteOptions.accidentAndIllnessCoverDuration
      ? getVetFeeLimit(quote)
      : undefined,
    quotedPremium: formatInPoundsWithSign(quote?.price?.annualPrice?.total),
    paymentMethod: quoteOptions.isAnnualPayment ? 'Annual' : 'Monthly',
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  };

  if (userHasSelectedCover(quote)) {
    event.coverType = getCoverTypeLabelFromCoverLevel(
      getCoverLevelFromQuoteOptions(quote.quoteOptions)
    );
  }

  if (quote.petInfos) {
    event.petCount = quote.petInfos.length?.toString();
    event.petType = quote.petInfos
      .map((pet) => (pet.petType === petType_CAT ? 'Cat' : 'Dog'))
      .join();
    event.breed = quote.petInfos.map((pet) => pet.petBreed).join();
    event.breedType = quote.petInfos
      .map((pet) => getReadablePetBreedType(pet.petBreedType))
      .join();
    event.petAge = getPetAge(quote);
    event.vetFeeExcess = quote.petInfos
      .map((petInfo) => getReadableExcessAmount(petInfo.voluntaryExcessAmount))
      .join();
    event.additionalExcess = quote.petInfos
      .map((petInfo) => `${parseInt(petInfo.voluntaryExcessPercentage, 10)}%`)
      .join();
  }

  if (quote.policyInfo) {
    event.daysUntilStartDate = getDifferenceInDates(
      new Date(),
      quote?.policyInfo?.coverStartDate
    );
  }

  trackEvent(event);
};

// Hook to track once when the quote loads
export const usePageTracking = (
  metaTitle: string,
  pageLoaded = true,
  quoteToTrack?: CurrentQuote | null
): void => {
  const [tracked, setTracked] = useState(false);
  const { pathname } = useLocation();
  const getFullMetaTitle = useMetaTitle();
  const error = useSelector((state: RootState) => state.error);
  const currentQuote = useCurrentQuote();
  const [customerDetails] = useCustomerDetails();
  const pageName = getFullMetaTitle(metaTitle);
  const quote = quoteToTrack ?? currentQuote;

  useEffect(() => {
    if (!tracked && !error.errorType && metaTitle && pageLoaded) {
      trackPageView(pathname, pageName, quote, customerDetails);
      setTracked(true);
    }
  }, [
    error.errorType,
    metaTitle,
    pathname,
    getFullMetaTitle,
    tracked,
    customerDetails,
    pageLoaded,
    quoteToTrack,
    quote,
    pageName,
  ]);
};
