import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { isPet8YearsOrOlderOnDate } from 'businessLogic/petAge';
import {
  CoverLevel,
  VoluntaryExcessAmount,
  VoluntaryExcessPercentage,
} from 'helpers/businessConstants';
import { conditionalValue } from 'helpers/mappingHelpers';
import { isQuoteFromAggs } from 'helpers/productHelpers';
import {
  catBreedType_PEDIGREE,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { PetsDetails } from 'state/formData/petsDetails';
import { QuoteRequestPetInfo } from '../quoteRequest';
import { Quote } from '../quoteResponse';

const mapPetsInfo = (
  petsDetails: PetsDetails,
  coverStartDate: Date,
  storedQuote?: Quote
): QuoteRequestPetInfo[] =>
  petsDetails.map((petDetails) => {
    const isCat = petDetails.petType === petType_CAT;
    const isPedigreeCat = isCat && petDetails.catBreedType === catBreedType_PEDIGREE;
    const isDog = petDetails.petType === petType_DOG;
    const isMongrelDog = isDog && petDetails.dogBreedType === dogBreedType_MONGREL;
    const isPedigreeDog = isDog && petDetails.dogBreedType === dogBreedType_PEDIGREE;

    const getPetBreed = (): string => {
      if (isCat) {
        return isPedigreeCat
          ? petDetails.catPedigreeBreedName
          : petDetails.catNonPedigreeBreedName;
      }
      if (isMongrelDog) {
        return '';
      }
      return isPedigreeDog
        ? petDetails.dogPedigreeBreedName
        : petDetails.dogCrossBreedName;
    };

    const getPetBreedType = (): string => {
      if (isCat) {
        return petDetails.catBreedType;
      }
      if (isMongrelDog) {
        return petDetails.mongrelSize;
      }
      return petDetails.dogBreedType;
    };

    const getPetExcess = (): VoluntaryExcessAmount => {
      // This will cause issues if 2 pets are given the same name
      // but this has been accepted as the best method with the current API
      const matchedPet = storedQuote?.petInfos.find(
        (pet) => pet.petName === petDetails.petName
      );

      return matchedPet
        ? matchedPet.voluntaryExcessAmount
        : VoluntaryExcessAmount.EXCESS_120;
    };

    const getInitialExcessPercentage = (): VoluntaryExcessPercentage =>
      isQuoteFromAggs(storedQuote) &&
      storedQuote?.petInfos[0]?.coverLevelRequired !== CoverLevel.ACCIDENT_AND_INJURY
        ? VoluntaryExcessPercentage.EXCESS_20_PERCENT
        : VoluntaryExcessPercentage.EXCESS_0_PERCENT;

    const getPetExcessPercentage = (): VoluntaryExcessPercentage => {
      // This will cause issues if 2 pets are given the same name
      // but this has been accepted as the best method with the current API
      const matchedPet = storedQuote?.petInfos.find(
        (pet) => pet.petName === petDetails.petName
      );

      if (
        isPet8YearsOrOlderOnDate(
          dateValueToISODateString(petDetails.petDob),
          coverStartDate
        )
      ) {
        return VoluntaryExcessPercentage.EXCESS_20_PERCENT;
      }

      return matchedPet
        ? matchedPet.voluntaryExcessPercentage
        : getInitialExcessPercentage();
    };

    return {
      petName: petDetails.petName,
      petType: petDetails.petType,
      petBreedType: getPetBreedType(),
      petBreed: getPetBreed(),
      petGender: petDetails.petGender,
      dob: dateValueToISODateString(petDetails.petDob) || '',
      initialCostPound: petDetails.petCost,
      initialCost: petDetails.petCost !== 0,
      isPetSpayed: petDetails.petSpayed,
      isPetChippedOrTagged: petDetails.petChipped,
      isPetAtHomeAddress: petDetails.petIsEligible,
      isPetShowingIllness: !petDetails.petInGoodHealth,
      legalActionInPreviousFiveYears: conditionalValue(isDog, !petDetails.petIsEligible),
      isComplaintMade: conditionalValue(isDog, !petDetails.petIsEligible),
      voluntaryExcessAmount: getPetExcess(),
      voluntaryExcessPercentage: getPetExcessPercentage(),
    };
  });

export default mapPetsInfo;
