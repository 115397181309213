import { petApiService } from './apiService';
import { ClubcardDetailsResponse } from './clubcard/details';
import { ClubcardSearchQueryParams, ClubcardSearchResponse } from './clubcard/search';

type ClubcardClient = {
  details: (clubcardNumber: string) => Promise<ClubcardDetailsResponse>;
  search: (address: ClubcardSearchQueryParams) => Promise<ClubcardSearchResponse>;
};

const clubcardClient: ClubcardClient = {
  details: (clubcardNumber: string) =>
    petApiService.get<ClubcardDetailsResponse>(`clubcard/${clubcardNumber}`),
  search: (nameAndAddress: ClubcardSearchQueryParams) =>
    petApiService.get<ClubcardSearchResponse>('clubcard/search', nameAndAddress),
};

export default clubcardClient;
