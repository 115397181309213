import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Lottie, {
  LottieAnimation,
} from '@rsa-digital/evo-shared-components/components/Lottie';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import WhyChooseUsSection from 'components/WhyChooseUsSection';
import { PageTitle } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';
import {
  Container,
  Divider,
  GridWithMargin,
  Heading,
  HeadingAndAnimationGridItem,
  HeadingAndAnimationWrapper,
  MobileDivider,
  Subheading,
  WhyChooseUsGridItem,
} from './styles';

type LoadingAnimationProps = {
  heading: string;
  subheading: string;
  animation: CsAsset;
} & ComponentProps;

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  heading,
  subheading,
  animation,
}) => {
  const lottieAnimation: LottieAnimation = {
    url: animation?.localAsset?.publicURL,
    altText: animation?.description || undefined,
  };
  return (
    <>
      <GridWithMargin>
        <HeadingAndAnimationGridItem
          desktop={6}
          tabletLandscape={6}
          tabletPortrait={6}
          aria-hidden>
          <Container>
            <HeadingAndAnimationWrapper>
              <GridItem
                desktop={3}
                tabletLandscape={3}
                tabletPortrait={3}
                mobile={2}
                aria-hidden>
                <Lottie animation={lottieAnimation} />
              </GridItem>
              <Heading>{heading}</Heading>
            </HeadingAndAnimationWrapper>
            <Subheading>{subheading}</Subheading>
          </Container>
          <MobileDivider />
        </HeadingAndAnimationGridItem>
        <WhyChooseUsGridItem desktop={5} tabletLandscape={5} tabletPortrait={5}>
          <WhyChooseUsSection pageTitle={PageTitle.QuoteGenerating} />
        </WhyChooseUsGridItem>
      </GridWithMargin>
      <Divider />
    </>
  );
};

export default LoadingAnimation;
