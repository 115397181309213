import { UPDATE_DISABLE_DATE_CHECKS, UpdateDisableDateChecksAction } from './actions';
import { getInitialDisableDateChecks } from './saga';

type TimeTravelState = {
  disableDateChecks: boolean;
};

const timeTravelReducer = (
  state: TimeTravelState = {
    disableDateChecks: getInitialDisableDateChecks(),
  },
  action: UpdateDisableDateChecksAction
): TimeTravelState => {
  switch (action.type) {
    case UPDATE_DISABLE_DATE_CHECKS:
      return { disableDateChecks: action.disableDateChecks };
    default:
      return state;
  }
};

export default timeTravelReducer;
