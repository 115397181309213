/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import createStore from './createStore';

declare global {
  interface Window {
    Cypress: any;
    store: any;
  }
}

const StoreProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const store = createStore();

  useEffect(() => {
    // expose store when run in Cypress
    if (window.Cypress) {
      window.store = store;
    }
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
