import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const RichTextWithModalAndMargins = styled(RichTextWithModal)`
  margin-top: ${spacing(3.5)};

  h2 {
    ${fonts.headingXSmall};
  }

  *:first-child {
    margin-top: 0;
  }

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
  `};
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-top: 0;
    margin-bottom: ${spacing(5)};

    ${mediaQuery.tabletPortrait`    
      margin-top: ${spacing(5)};
      margin-bottom: ${spacing(9)};
    `};

    ${mediaQuery.tabletLandscape`    
      margin-bottom: ${spacing(6)};
    `};
  }

  flex-flow: column-reverse;

  ${mediaQuery.tabletPortrait`
    flex-flow: row;
  `};
`;
