import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import ProgressBar from '@rsa-digital/evo-shared-components/components/ProgressBar';
import {
  StepDone,
  StepName,
} from '@rsa-digital/evo-shared-components/components/ProgressBar/ProgressDesktop/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledProgressBar = styled(ProgressBar)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(2)};
  position: relative;
  z-index: 1;
  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}
  ${StepDone} + ${StepName} {
    color: ${colors.core01};
     ${mediaQuery.tabletPortrait`
      color: ${colors.neutral01};
    `}
  }
`;

export const StyledBackground = styled(PageWidthContainer)`
  display: hidden;
  ${mediaQuery.tabletPortrait`
    display: block;
    height: ${spacing(1.75)};
    background-color: ${colors.core02};
  `}
`;
