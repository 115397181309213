import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';

export const initialDateValue: DateValue = {
  day: '',
  month: '',
  year: '',
};

export const initialDateValueWithDefaultDay: DateValue = {
  day: '01',
  month: '',
  year: '',
};
