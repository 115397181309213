import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle, trackFooterLinkClick } from 'helpers/eventTracking';
import { GridWithMargin, RichTextWithModalAndMargins } from './styles';

type CsComplianceFooter = {
  csPetFooter: {
    compliance_footer: {
      compliance_text: string;
      contact_us_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetFooter {
      compliance_footer {
        compliance_text
        contact_us_text
      }
    }
  }
`;

const ComplianceFooter: React.FC<ComponentProps & { pageTitle: PageTitle }> = ({
  pageTitle,
  ...props
}) => {
  const footerText = useStaticQuery<CsComplianceFooter>(query).csPetFooter
    .compliance_footer;
  return (
    <GridWithMargin {...componentProps(props)}>
      <GridItem desktop={7} tabletLandscape={7} tabletPortrait={4}>
        <RichTextWithModalAndMargins
          pageTitle={pageTitle}
          html={footerText.compliance_text}
          onLinkClick={trackFooterLinkClick}
        />
      </GridItem>
      <GridItem desktop={1} tabletLandscape={1} tabletPortrait={1} />
      <GridItem desktop={4} tabletLandscape={4} tabletPortrait={3}>
        <RichTextWithModalAndMargins
          pageTitle={pageTitle}
          html={footerText.contact_us_text}
          onLinkClick={trackFooterLinkClick}
        />
      </GridItem>
    </GridWithMargin>
  );
};

export default ComplianceFooter;
