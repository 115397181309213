import { OptanonType } from './eventTracking';

export const ADOBE_ORG_ID = '9428E38C5475D7250A4C98A7@AdobeOrg';

type AdobeVisitor = {
  getInstance: (
    adobeOrgId: string
  ) => {
    getMarketingCloudVisitorID: () => string;
  };
};

declare global {
  interface Window {
    tb_ddl?: TescoTrackingEvent[];
    Visitor?: AdobeVisitor;
  }
}

export type TescoPageLoadEvent = {
  event: string;
  journey: { journeyinfo: { journey_id: string | undefined } };
  user: {
    userinfo: {
      user_postcode_region: string | undefined;
      user_language_selected: string | undefined;
      user_device_recognised: boolean;
      user_logged_in: boolean;
    };
  };
  version: {
    versioninfo: {
      version_id: string | undefined;
    };
  };
};

export enum TescoPageViewEventLevel {
  basic = 'BASIC',
  extended = 'EXTENDED',
  error = 'ERROR',
}

type TescoPage = {
  pageinfo: {
    page_type: string | undefined;
    page_name: string | undefined;
    page_url: string | undefined;
    page_url_domain: string | undefined;
    page_url_path: string | undefined;
    page_url_querystring: string | undefined;
    page_pcw_landing_page: boolean;
    page_previous_name: string | undefined;
    page_tag_group: string | undefined;
  };
  attributes: {
    page_referrer_url: string | undefined;
    page_referrer_domain: string | undefined;
    page_referrer_page_name: string | undefined;
    page_referrer_PCW_code: string | undefined;
    page_hit_datestamp: string | undefined;
    page_hit_timestamp: string | undefined;
  };
};

type PetType = 'dog' | 'cat';

type TescoApplication = {
  applicationinfo: {
    application_aperio_id: string | undefined;
    application_id: string | undefined;
    application_case_id: string | undefined;
    application_digital_id: string | undefined;
    application_prepopulated: boolean | undefined;
    application_journey_type: string | undefined;
  };
  applicationattributes?: {
    attributedetail: {
      number_of_pets: string | undefined;
      pet_type: PetType[];
    };
  };
};

export type TescoBasePageViewEvent = {
  event: string;
  page: TescoPage;
  application?: TescoApplication;
};

type TescoPromotion = {
  promotions_id: string | undefined;
  promotions_discount_amount: string | undefined;
};

export type TescoPetExcesses = {
  agreement_excess_mandatory: string | undefined;
  agreement_excess_voluntary: string | undefined;
};

type TescoProductItem = {
  productinfo: {
    product_id: string | undefined;
    product_position: string | undefined;
    product_tier: string | undefined;
    product_auto_applied: boolean;
    product_quantity: string | undefined;
    product_price: string | undefined;
  };
  agreementinfo: {
    agreement_decision_id: string | undefined;
    agreement_sale_id: string | undefined;
    agreement_duration_months: string | undefined;
    excesses: TescoPetExcesses[];
    agreement_num_persons: string | undefined;
    agreement_start_date: string | undefined;
    agreement_end_date: string | undefined;
    agreement_renewal_date: string | undefined;
    agreement_outcome: string | undefined;
  };
  promotions: TescoPromotion[];
  paymentinfo: {
    payment_paperless_bill: boolean;
    payment_day_of_the_month: string | undefined;
    payment_frequency: string | undefined;
    payment_duration_months: string | undefined;
    payment_deposit_amount: string | undefined;
    payment_recurring_amount: string | undefined;
    payment_amount: string | undefined;
  };
};

export type TescoBasket = {
  basket?: {
    basketinfo: {
      item: TescoProductItem[];
      basket_payment_amount: string | undefined;
    };
  };
};

export type TescoPageViewEvent = TescoBasePageViewEvent & TescoBasket;
export type TescoTrackingEvent = TescoPageLoadEvent | TescoPageViewEvent | OptanonType;

export const trackTescoEvent = (tescoEvent: TescoTrackingEvent): void => {
  if (typeof window !== 'undefined') {
    window.tb_ddl = window.tb_ddl || [];
    window.tb_ddl.push(tescoEvent);
  }
};
