type actionArg = {
  action: string;
};

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

// window.grecaptcha is created by the grecaptcha script
// loaded in <head>
declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (key: string, action: actionArg) => PromiseLike<string>;
    };
  }
}

const getRecaptchaToken = (key: string | undefined): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (activeEnv === 'test') {
      resolve('testToken');
    } else {
      window.grecaptcha.ready(async () => {
        // It shouldn't be undefined, but double-check anyway.
        /* istanbul ignore if */
        if (!key) {
          reject(new Error('reCAPTCHA key not found'));
        } else {
          try {
            const token = await window.grecaptcha.execute(key, { action: 'submit' });
            resolve(token);
          } catch (err) {
            reject(err);
          }
        }
      });
    }
  });
};

export const getPetApiRecaptchaToken = (): Promise<string> => {
  const key = process.env.GATSBY_RECAPTCHA_PET_SITE_KEY;
  // GATSBY_RECAPTCHA_PET_SITE_KEY is checked during build (gatsby-config.js)
  return getRecaptchaToken(key);
};

export const getEmailApiRecaptchaToken = (): Promise<string> => {
  const key = process.env.GATSBY_RECAPTCHA_EMAIL_SITE_KEY;
  return getRecaptchaToken(key);
};
