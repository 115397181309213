import { NonTextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ProgressBar, { QuoteAndBuyStep } from 'components/ProgressBar';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackIconButtonClick } from 'helpers/eventTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsAsset } from 'types/contentStack';
import {
  BackgroundContainer,
  Hero,
  ImageWrapper,
  SizedImage,
  StyledImage,
  StyledRichText,
  Subheading,
} from './styles';

type HeroBannerProps = {
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
  introText?: string;
  introTextIsPii?: boolean;
  pageTitle: PageTitle;
  currentStep?: QuoteAndBuyStep;
  isErrorPage?: boolean;
} & ComponentProps;

type CsHeroBanner = {
  csHeroHeader: {
    tesco_bank_logo: CsAsset | null;
    logo_link_url: string;
    link_screen_reader_text: string;
  };
};

const query = graphql`
  query {
    csHeroHeader {
      tesco_bank_logo {
        ...CsAsset
      }
      logo_link_url
      link_screen_reader_text
    }
  }
`;

const HeroBanner: React.FC<HeroBannerProps> = ({
  heading,
  subheading,
  subheadingIsPii,
  introText,
  introTextIsPii,
  pageTitle,
  currentStep,
  isErrorPage,
  ...props
}) => {
  const { tesco_bank_logo, logo_link_url, link_screen_reader_text } = useStaticQuery<
    CsHeroBanner
  >(query).csHeroHeader;
  const tescoBankLogo = processImageAsset(tesco_bank_logo);
  const logoLink: NonTextLink = {
    url: logo_link_url,
    screenReaderText: link_screen_reader_text,
    onClick: () => trackIconButtonClick(pageTitle, 'Tesco Logo'),
  };

  const isAboutYouOrConfirmationPage =
    pageTitle === PageTitle.AboutYouAndYourPet || pageTitle === PageTitle.Confirmation;
  const isAdditionalQuestionsPage = pageTitle === PageTitle.AdditionalQuestions;
  const logoLinkSelectPagesOnly = isAboutYouOrConfirmationPage ? logoLink : undefined;

  const quote = useCurrentQuote();
  const aggregatorBranding = useAggregatorBrandDetails(quote.productId);

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  return (
    <BackgroundContainer {...componentProps(props)}>
      {tescoBankLogo && (
        <ImageWrapper>
          <SizedImage
            link={logoLinkSelectPagesOnly}
            image={tescoBankLogo}
            removeLinkPadding={isAboutYouOrConfirmationPage}
          />
        </ImageWrapper>
      )}
      {currentStep && <ProgressBar currentStep={currentStep} pageTitle={pageTitle} />}
      {heading && (
        <>
          {isErrorPage ? (
            <Grid alignLeft>
              <GridItem desktop={9} tabletLandscape={9}>
                <Hero>{plainTextPlaceholderReplacer(heading)}</Hero>
              </GridItem>
            </Grid>
          ) : (
            <Hero>{plainTextPlaceholderReplacer(heading)}</Hero>
          )}
        </>
      )}
      {aggregatorBranding && isAdditionalQuestionsPage && (
        <>{aggregatorBranding?.logo && <StyledImage image={aggregatorBranding.logo} />}</>
      )}
      <Grid alignLeft>
        <GridItem desktop={9} tabletLandscape={9}>
          {subheading && (
            <Subheading data-pii-mask={subheadingIsPii}>{subheading}</Subheading>
          )}
          {introText && (
            <StyledRichText
              html={introText}
              data-pii-mask={introText}
              data-cy="intro_text"
            />
          )}
        </GridItem>
      </Grid>
    </BackgroundContainer>
  );
};

export default HeroBanner;
