import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeadingAndAnimationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${mediaQuery.desktop`
    border: 0;
  `}

  ${mediaQuery.tabletLandscape`
    flex-direction: column;
    margin-top: ${spacing(8)}
    justify-content: left;
    height: 80%;
    width: 100%;
  `}
`;

export const Heading = styled.h1`
  ${fonts.headingMedium}
  & {
    color: ${colors.core01};
  }
  margin-top: 0;
  margin-bottom: ${spacing(2)};
  margin-left: ${spacing(3.5)};

  ${mediaQuery.tabletPortrait`
    ${fonts.headingMedium}
    & {
      color: ${colors.core01};
    }
  `}

  ${mediaQuery.tabletLandscape`
    padding: 0 ${spacing(3)};
    text-align: center;
  `}
`;

export const Subheading = styled.p`
  ${fonts.paragraphSmall}
  text-align: center;
  margin-top: ${spacing(4)};
  margin-bottom: 0;

  ${mediaQuery.tabletPortrait`
    ${fonts.paragraphLarge}
    margin-top: ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: 0 ${spacing(3)};
  `}
`;

export const MobileDivider = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const Divider = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(8)};
    padding-bottom: ${spacing(1)};
  `}
`;

export const WhyChooseUsGridItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    padding: 0 ${spacing(4)};
  `}
`;

export const HeadingAndAnimationGridItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    display: flex;
    align-items: center;
  `}
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-top: ${spacing(4)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(8)};
    `}

    ${mediaQuery.tabletLandscape`
      margin-left: 0;
      margin-right: 0;
    `}
  }
`;
