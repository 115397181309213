import referenceDataClient from 'apiHelpers/referenceDataClient';
import { AxiosError } from 'axios';
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from 'redux-saga/effects';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import { trackAPIError } from 'helpers/eventTracking';
import { RootState } from 'state/createStore';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import {
  REFERENCE_DATA_FETCHED,
  REFERENCE_DATA_LOADING,
  REFERENCE_DATA_REQUESTED,
  ReferenceDataAction,
  RequestReferenceDataAction,
} from './actions';
import { ReferenceData, ReferenceDataKey, ReferenceDataStatus } from './state';

type ReferenceDataGenerator = Generator<
  | CallEffect<ReferenceData[ReferenceDataKey]>
  | PutEffect<ReferenceDataAction | ErrorAction>
  | SelectEffect
>;

function* fetchReferenceData({
  dataType,
}: RequestReferenceDataAction): ReferenceDataGenerator {
  const currentStatus = yield select(
    (state: RootState) => state.referenceData[dataType].status
  );
  if (currentStatus !== ReferenceDataStatus.NotLoaded) {
    return;
  }
  yield put({ type: REFERENCE_DATA_LOADING, dataType });
  try {
    const data = yield call(referenceDataClient[dataType]);
    yield put({
      type: REFERENCE_DATA_FETCHED,
      dataType,
      data: data as ReferenceData[ReferenceDataKey],
    });
  } catch (err) {
    const error: AxiosError = err as AxiosError;
    trackAPIError(error);
    if (isAxiosError(error) && error.response) {
      console.error(
        `Error when making API request for reference data '${dataType}': server returned ${error.response.status}`,
        ...(error.response.data ? [' with data ', error.response.data] : [])
      );
    } else {
      console.error(
        `Error when making API request for reference data '${dataType}': `,
        error.message
      );
    }
    const errorAction: ErrorAction = {
      type: ERROR,
      errorType: ErrorType.API_ERROR,
      statusCode: error.response?.status,
    };
    yield put(errorAction);
  }
}

function* handleReferenceDataRequest(): Generator<ForkEffect<never>> {
  yield takeEvery(REFERENCE_DATA_REQUESTED, fetchReferenceData);
}

export default handleReferenceDataRequest;
