import React from 'react';
import { Helmet } from 'react-helmet-async';
import appdynamicsScripts from './appdynamics';
import { gtmNoscript, gtmScript } from './gtm';
import { gtmTescoNoscript, gtmTescoScript } from './gtmTesco';
import oneTrustScripts from './onetrust';
import optimizelyScript from './optimizely';

const Analytics: React.FC = () => (
  <>
    <Helmet>
      {optimizelyScript}
      {oneTrustScripts}
      {gtmScript}
      {gtmTescoScript}
      {appdynamicsScripts}
    </Helmet>
    {gtmNoscript}
    {gtmTescoNoscript}
  </>
);

export default Analytics;
