export const QUOTE_UUID_SESSION_KEY = 'QUOTE_UUID';
export const QIP_UUID_SESSION_KEY = 'QUOTE_IN_PROGRESS_UUID';
export const QUOTE_OPTIONS_SESSION_KEY = 'QUOTE_OPTIONS';
export const TRANSACTION_ID_SESSION_KEY = 'TRANSACTION_ID';
export const ASSUMPTIONS_AGREEMENT_SESSION_KEY = 'ASSUMPTIONS_AGREEMENT';
export const DISPLAY_COOKIE_BANNER_SESSION_KEY = 'DISPLAY_COOKIE_BANNER_SESSION_KEY';
export const RECAPTCHA_SKIP_KEY_SESSION_KEY = 'RECAPTCHA_SKIP_KEY_SESSION_KEY';
export const PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY =
  'PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY';
export const DISABLE_DATE_CHECKS_STORAGE_KEY = 'DISABLE_DATE_CHECKS';
export const TESCO_PAGE_LOAD_TRACKED_STORAGE_KEY = 'TESCO_PAGE_LOAD_TRACKED';
export const TESCO_PAGE_LOAD_POSTCODE = 'TESCO_PAGE_LOAD_POSTCODE';
export const TESCO_PAGE_VIEW_OBJECT = 'TESCO_PAGE_VIEW_OBJECT';
export const TESCO_PAGE_VIEW_IS_RETRIEVED_QUOTE = 'TESCO_PAGE_VIEW_IS_RETRIEVED_QUOTE';

// The keys included here will be cleared when the session expires or when the user completes the payment step.
const sessionInProgressKeys = [
  QIP_UUID_SESSION_KEY,
  QUOTE_OPTIONS_SESSION_KEY,
  QUOTE_UUID_SESSION_KEY,
  TRANSACTION_ID_SESSION_KEY,
  ASSUMPTIONS_AGREEMENT_SESSION_KEY,
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
];

export const storeData = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    sessionStorage.setItem(key, value);
  }
};

export const retrieveData = (key: string): string | undefined => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    return sessionStorage.getItem(key) || undefined;
  }
  return undefined;
};

export const removeData = (key: string): void => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    sessionStorage.removeItem(key);
  }
};

// Use ttl to indicate the period of validity of the value to store (payload parameter) in milliseconds
export const storeDataWithExpiry = (key: string, payload: string, ttl: number): void => {
  const now = new Date();
  const item = {
    value: payload,
    expiry: now.getTime() + ttl,
  };
  storeData(key, JSON.stringify(item));
};

export const retrieveDataWithExpiry = (key: string): string | undefined => {
  const stringifiedItem = retrieveData(key);
  if (!stringifiedItem || stringifiedItem === '[object Object]') {
    return undefined;
  }
  const item = JSON.parse(stringifiedItem);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    removeData(key);
    return undefined;
  }

  return item.value;
};

export const removeSessionData = (): void => {
  sessionInProgressKeys.forEach((key) => removeData(key));
};
