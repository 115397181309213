import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { getDateOnlyIsoString } from 'helpers/dateHelpers';
import { conditionalValue } from 'helpers/mappingHelpers';
import { CustomerDetails } from 'state/formData/customerDetails';
import { JointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { PolicyDetails } from 'state/formData/policyDetails';
import mapJointPolicyHolder from './mapJointPolicyHolder';
import { QuotePolicyInfo } from '../quoteRequest';

const mapPolicyInfo = (
  customerDetails: CustomerDetails,
  policyDetails: PolicyDetails,
  jointPolicyHolder: JointPolicyholderDetails,
  storedQuotePolicyInfo?: QuotePolicyInfo
): QuotePolicyInfo => ({
  coverStartDate: getDateOnlyIsoString(policyDetails.coverStartDate),
  numberPetInHousehold: customerDetails.numberOfPetsInHousehold,
  includeJoinPolicyHolder: jointPolicyHolder.includeJointPolicyholder,
  joinPolicyHolder: conditionalValue(
    jointPolicyHolder.includeJointPolicyholder,
    mapJointPolicyHolder(jointPolicyHolder)
  ),
  quoteNumber: storedQuotePolicyInfo?.quoteNumber || null,
  quoteStartDate: storedQuotePolicyInfo?.quoteStartDate,
  contactable: policyDetails.keepInformed,
  promotionalCode:
    policyDetails.promoCode.status === LookupStatus.Success
      ? policyDetails.promoCode.lookupKey
      : undefined,
  quoteExpiryDate: storedQuotePolicyInfo?.quoteExpiryDate,
});

export default mapPolicyInfo;
