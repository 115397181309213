import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import Modal from '@rsa-digital/evo-shared-components/components/Modal';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment, MouseEventHandler } from 'react';
import TreatmentCostModal from 'components/TreatmentCostModal';
import { mapCustomTable } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  RichTextClickTracker,
  trackModalOpen,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { CsTable } from 'types/contentStack';
import {
  ModalGridItem,
  ModalHeading,
  RichTextWithVerticalMargin,
  TableWithVerticalMargin,
} from './styles';

// Provide values that match the values of the 'select' field for the predefined tables in contentstack.
type PredefinedTable = string;

export type ModalBlock = {
  custom_table: {
    table: [{ table: CsTable }];
  } | null;
  predefined_table: {
    table: PredefinedTable;
  } | null;
  rich_text: {
    rich_text: string;
  } | null;
};

type ModalsData = {
  allCsModal: {
    nodes: {
      modal_id: string;
      heading: string;
      modal_blocks: ModalBlock[];
    }[];
  };
  csPetTypicalTreatmentCostModal: {
    modal_id: string;
  };
};

const modalsQuery = graphql`
  query {
    allCsModal {
      nodes {
        modal_id
        heading
        modal_blocks {
          custom_table {
            table {
              table {
                header_row {
                  has_empty_corner_cell
                  corner_cell_column_span
                  header_cells {
                    column_span
                    heading_text
                    is_highlighted
                  }
                }
                content_rows {
                  content_row {
                    cells {
                      cell {
                        content_rich_text
                        column_span
                        is_header_cell
                        is_highlighted
                      }
                    }
                  }
                }
              }
            }
          }
          predefined_table {
            table
          }
          rich_text {
            rich_text
          }
        }
      }
    }
    csPetTypicalTreatmentCostModal {
      modal_id
    }
  }
`;

type CustomModalProps = {
  modalId: string | null;
  modalLabel?: string;
  onClose: MouseEventHandler;
  richTextPlaceholderReplacer?: (csString: string) => string;
  trackRichTextLinkClick: RichTextClickTracker;
  pageTitle: PageTitle;
};

const renderModalBlock = (
  block: ModalBlock,
  richTextPlaceholderReplacer?: (csString: string) => string,
  trackRichTextLinkClick?: RichTextClickTracker
): React.ReactNode => {
  if (block.rich_text) {
    const html = richTextPlaceholderReplacer
      ? richTextPlaceholderReplacer(block.rich_text.rich_text)
      : block.rich_text.rich_text;
    return (
      <RichTextWithVerticalMargin html={html} onLinkClick={trackRichTextLinkClick} />
    );
  }
  if (block.custom_table) {
    return (
      <TableWithVerticalMargin {...mapCustomTable(block.custom_table.table[0].table)} />
    );
  }
  return null;
};

/**
 * Modals are defined in Contentstack using a content type with modular blocks.
 * This component accepts the ID (in Contentstack) of a modal, and renders it.
 */
const CustomModal: React.FC<CustomModalProps> = ({
  trackRichTextLinkClick,
  modalId,
  modalLabel,
  onClose,
  richTextPlaceholderReplacer,
  pageTitle,
}) => {
  const modalsData = useStaticQuery<ModalsData>(modalsQuery);
  if (!modalId) {
    return null;
  }

  if (modalId === modalsData.csPetTypicalTreatmentCostModal.modal_id) {
    return <TreatmentCostModal onClose={onClose} pageTitle={pageTitle} />;
  }

  const modalData = modalsData.allCsModal.nodes.find((n) => n.modal_id === modalId);

  if (!modalData) {
    console.error(`Modal not found - ${modalId}.`);
    return null;
  }

  return (
    <Modal
      onClose={(e) => {
        trackTextButtonClick(pageTitle, `Close ${modalId.replace(/(_)/g, ' ')}`);
        onClose?.(e);
      }}
      data-cy="Custom modal"
      aria-labelledby="custom-modal-heading"
      onOpen={() => trackModalOpen(modalLabel || modalId)}>
      <Grid>
        <ModalGridItem desktop={8} tabletLandscape={8}>
          <ModalHeading id="custom-modal-heading">{modalData.heading}</ModalHeading>
          {modalData.modal_blocks.map((block, i) => (
            // Using the index here is safe because each modal is *static* data from
            // contentstack, and we cannot update to a different modal without first
            // rendering no modal (and hence no blocks), so there will not be continuity
            // of keys between different modals.
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={i}>
              {renderModalBlock(
                block,
                richTextPlaceholderReplacer,
                trackRichTextLinkClick
              )}
            </Fragment>
          ))}
        </ModalGridItem>
      </Grid>
    </Modal>
  );
};

export default CustomModal;
