import { useCallback, useState } from 'react';

type WithLoadingState = <T>(asyncFunction: () => Promise<T>) => Promise<T>;

/**
 * A utility hook which provides state for tracking an in-flight asynchronous requests. Will generaly
 * be used in combination with some kind of loading spinner so that the user is aware a request is taking
 * place, and possibly to disable some buttons/ other user interaction.
 *
 * If multiple requests are being tracked, then multiple instances of this hook should be used.
 *
 * @returns Object with properties:
 * - isLoading - true if the request is in flight, false otherwise
 * - withLoadingState - a wrapper function for the asynchronous request
 *
 * @example
 * const { isLoading: fullAddressIsLoading, withLoadingState: withFullAddressLoadingState } = useLoadingState();
 * ...
 * const address = await withFullAddressLoadingState(
 *   () => addressLookupClient.getFullAddress(addressDetails.postcodeLookup.lookupKey, address.id)
 * );
 * ...
 * <button disabled={fullAddressIsLoading} />
 *
 * @example
 * const { isLoading: requestAIsLoading, ... } = useLoadingState();
 * const { isLoading: requestBIsLoading, ... } = useLoadingState();
 *  ...
 * return (
 *   <>
 *     {(requestAIsLoading || requestBIsLoading) &&
 *       <LoadingOverlay loadingMessage={requestAIsLoading ? "A message" : "B message"} />}
 *   </>
 * );
 */
const useLoadingState = (): {
  isLoading: boolean;
  withLoadingState: WithLoadingState;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const withLoadingState = useCallback(
    async <T,>(asyncFunction: () => Promise<T>): Promise<T> => {
      setIsLoading(true);
      try {
        return await asyncFunction();
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { isLoading, withLoadingState };
};

export default useLoadingState;
