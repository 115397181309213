import { eventEmitter } from './pubSub';
import { retrieveData, storeData } from './sessionStorageHelpers';

// TFS due to Gatsby trying to generate the build and not knowing document.
export const getCookie = (cookieName: string): string => {
  if (typeof window !== 'undefined') {
    const cookiesString = `; ${document?.cookie}`;
    const parts = cookiesString.split(`; ${cookieName}=`);
    const cookieValue = parts.length === 2 ? parts.pop()?.split(';').shift() : '';
    return cookieValue ?? '';
  }

  return '';
};

// Sets (creates or replaces) a browser cookie
// If expiration date is not used expiration will be set to "Session"
export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expirationDate?: Date
): void => {
  if (typeof window !== 'undefined') {
    if (typeof expirationDate !== 'undefined') {
      document.cookie = `${cookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}`;
    } else {
      document.cookie = `${cookieName}=${cookieValue};`;
    }
  }
};

export const deleteCookie = (cookieName: string): void => {
  // Set cookie with expiration date = yesterday
  setCookie(cookieName, '', new Date(Date.now() - 864e5));
};

// Publish-subscribe cookie consents when modified
if (typeof window !== 'undefined') {
  const ONETRUST_COOKIES = 'ONETRUST_COOKIES';
  const initialCookie = getCookie('OptanonConsent');
  storeData(ONETRUST_COOKIES, initialCookie);
  setInterval(() => {
    const old = retrieveData(ONETRUST_COOKIES);
    const latest = getCookie('OptanonConsent');
    if (!!old && old !== latest) {
      storeData(ONETRUST_COOKIES, latest);
      eventEmitter.publish('onetrustCookieUpdate', document.cookie);
    }
  }, 1000);
}
