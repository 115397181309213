import useUpdateQuoteCover, { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useReducer } from 'react';
import CustomModal from 'components/CustomModal';
import { CsModal } from 'types/contentStack';
import { unwrapSingleton } from './csTypeProcessors';
import useDefaultErrorHandling from './errorHandling';
import { PageTitle, trackRichTextLinkClick, trackTextButtonClick } from './eventTracking';
import { quotePlaceholders } from './placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersRichText,
} from './placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from './useCurrentQuote';

type CsSaveModalProps = {
  csPetQuoteSummary: {
    successful_save_modal: [CsModal] | [];
  };
};

type UpdateQuoteOptions = {
  onSuccess?: () => void;
  triggerEmail?: boolean;
  quoteUpdaters?: QuoteUpdaters;
};

const query = graphql`
  query {
    csPetQuoteSummary {
      successful_save_modal {
        modal_id
      }
    }
  }
`;

export const trySaveQuote = async ({
  onSuccess,
  triggerEmail,
  quoteUpdaters,
}: UpdateQuoteOptions): Promise<void> => {
  if (!quoteUpdaters) {
    console.error('Cannot update quote - No quote loaded');
    return;
  }
  await quoteUpdaters.saveQuoteOptions(triggerEmail);
  if (onSuccess) {
    onSuccess();
  }
};

export const useSaveAndEmailQuote = (
  pageTitle: PageTitle
): {
  saveAndEmailQuote: () => Promise<void>;
  savedQuoteConfirmationModal: JSX.Element;
  savedQuoteConfirmationModalForQS: JSX.Element;
  isSaveInProgress: boolean | undefined;
} => {
  const { successful_save_modal } = useStaticQuery<CsSaveModalProps>(
    query
  ).csPetQuoteSummary;

  const quote = useCurrentQuote();
  const [quoteSavedModalId, toggleQuoteSavedModal] = useReducer((state) => {
    const modalId = unwrapSingleton(successful_save_modal)?.modal_id ?? null;
    return !state ? modalId : null;
  }, null);
  const quoteUpdaters = useUpdateQuoteCover();
  const defaultErrorHandling = useDefaultErrorHandling();

  const richTextPlaceholderReplacer = quote
    ? replacePlaceholdersRichText(quotePlaceholders, quote)
    : noOpPlaceholderReplacer;

  const saveAndEmailQuote = async (): Promise<void> => {
    try {
      await trySaveQuote({
        onSuccess: () => {
          toggleQuoteSavedModal();
        },
        triggerEmail: true,
        quoteUpdaters,
      });
    } catch (err) {
      const error: Error = err as Error;
      defaultErrorHandling(error);
    }
  };

  const savedQuoteConfirmationModal = (
    <CustomModal
      modalId={quoteSavedModalId}
      onClose={() => {
        trackTextButtonClick(pageTitle, 'close save quote modal');
        toggleQuoteSavedModal();
      }}
      richTextPlaceholderReplacer={richTextPlaceholderReplacer}
      trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
      pageTitle={pageTitle}
    />
  );

  const savedQuoteConfirmationModalForQS = (
    <CustomModal
      modalId={quoteSavedModalId}
      modalLabel={`${quoteSavedModalId}_box`}
      onClose={() => {
        trackTextButtonClick(pageTitle, 'close save quote modal');
        toggleQuoteSavedModal();
      }}
      richTextPlaceholderReplacer={richTextPlaceholderReplacer}
      trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
      pageTitle={pageTitle}
    />
  );

  return {
    saveAndEmailQuote,
    savedQuoteConfirmationModal,
    savedQuoteConfirmationModalForQS,
    isSaveInProgress: quoteUpdaters?.isLoading,
  };
};
