import { Assumptions, getAssumptionsForQuote } from 'businessLogic/aggregatorAssumptions';
import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';

type CsAggregatorAssumptions = {
  csPetAggregators: {
    assumptions: {
      good_health: {
        assumption_single_pet: string;
        assumption_multiple_pets: string;
      };
      no_complaints_about_behaviour: {
        assumption: string;
      };
      not_involved_in_legal_action: {
        assumption: string;
      };
      one_pet_in_household: {
        assumption: string;
      };
      two_pets_in_household: {
        assumption: string;
      };
      three_pets_in_household: {
        assumption: string;
      };
      pet_lives_with_you: {
        assumption: string;
      };
      dog_breed_list_link: {
        assumption: string;
      };
      pet_for_make_money: {
        assumption: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAggregators {
      assumptions {
        good_health {
          assumption_single_pet
          assumption_multiple_pets
        }
        no_complaints_about_behaviour {
          assumption
        }
        not_involved_in_legal_action {
          assumption
        }
        one_pet_in_household {
          assumption
        }
        two_pets_in_household {
          assumption
        }
        three_pets_in_household {
          assumption
        }
        dog_breed_list_link {
          assumption
        }
        pet_lives_with_you {
          assumption
        }
        pet_for_make_money {
          assumption
        }
      }
    }
  }
`;

const useAssumptions = (): Assumptions | undefined => {
  const {
    csPetAggregators: { assumptions: assumptionsContent },
  } = useStaticQuery<CsAggregatorAssumptions>(query);
  const currentQuote = useCurrentQuote();

  const [assumptionsAgreement] = useAssumptionsAgreement();

  if (assumptionsAgreement.assumptionsInvalidDueToRequote) {
    return undefined;
  }

  const assumptions = getAssumptionsForQuote(
    currentQuote,
    {
      goodHealthSinglePet: assumptionsContent.good_health.assumption_single_pet,
      goodHealthMultiplePets: assumptionsContent.good_health.assumption_multiple_pets,
      noComplaintsAboutBehaviour:
        assumptionsContent.no_complaints_about_behaviour.assumption,
      notInvolvedInLegalAction:
        assumptionsContent.not_involved_in_legal_action.assumption,
      onePetInHousehold: assumptionsContent.one_pet_in_household.assumption,
      twoPetsInHousehold: assumptionsContent.two_pets_in_household.assumption,
      threePetsInHousehold: assumptionsContent.three_pets_in_household.assumption,
      petLivesWithYou: assumptionsContent?.pet_lives_with_you?.assumption,
      petForMakeMoney: assumptionsContent?.pet_for_make_money?.assumption,
      dogBreedListLink: assumptionsContent?.dog_breed_list_link?.assumption,
    },
    assumptionsAgreement.touchedAssumptions
  );

  /*  If any of the assumptions are invalid, then we know that the quote has been updated 
      by the user after being created by the aggregator.  This means that we know that the 
      assumptions are not actually assumptions any more, and so we do not return any invalid assumptions.
  */
  return (
    assumptions && {
      assumptions: assumptions.assumptions.filter((assumption) => assumption.isValid),
      fieldIdOfFirstAssumption: assumptions.fieldIdOfFirstAssumption,
    }
  );
};

export default useAssumptions;
