import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export const UPDATE_AGE_8_EXCESS_AGREEMENTS = 'UPDATE_AGE_8_EXCESS_AGREEMENTS';

export type Age8ExcessAgreements = {
  petUnder8: boolean | undefined;
  petOver8: boolean | undefined;
};

export const initialAge8ExcessAgreements: Age8ExcessAgreements = {
  petUnder8: undefined,
  petOver8: undefined,
};

export type UpdateAge8ExcessAgreementsAction = {
  type: typeof UPDATE_AGE_8_EXCESS_AGREEMENTS;
  update: Partial<Age8ExcessAgreements>;
};

export const useAge8ExcessAgreements = (): [
  Age8ExcessAgreements,
  (update: Partial<Age8ExcessAgreements>) => void
] => {
  const age8ExcessAgreements = useSelector(
    (state: RootState) => state.age8ExcessAgreements
  );
  const dispatch = useDispatch();

  const updateAge8ExcessAgreements = useCallback(
    (update: Partial<Age8ExcessAgreements>): void => {
      dispatch({ type: UPDATE_AGE_8_EXCESS_AGREEMENTS, update });
    },
    [dispatch]
  );

  return [age8ExcessAgreements, updateAge8ExcessAgreements];
};
