export enum CoverType {
  Accidents_Only = 'Accidents_Only',
  Accident_And_Illness = 'Accident_And_Illness',
}

export enum AccidentAndIllnessCoverDuration {
  Short_Term = 'standard',
  Until_Limit = 'extra',
  Ongoing = 'premier',
}

export enum ExtraCoverFeeLimit {
  Limit_4000 = 4000,
  Limit_7500 = 7500,
}

export enum PremierCoverFeeLimit {
  Limit_2000 = 2000,
  Limit_4000 = 4000,
  Limit_7500 = 7500,
  Limit_10000 = 10000,
}

export type QuoteOptions = {
  coverType: CoverType | undefined;
  accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration | undefined;
  extraCoverFeeLimit: ExtraCoverFeeLimit | undefined;
  premierCoverFeeLimit: PremierCoverFeeLimit | undefined;
  isAnnualPayment: boolean;
  additionalQuestionCheapestPremier: boolean | undefined;
  additionalQuestionStandard: boolean | undefined;
};

export const initialQuoteOptions: QuoteOptions = {
  coverType: CoverType.Accident_And_Illness,
  accidentAndIllnessCoverDuration: undefined,
  extraCoverFeeLimit: undefined,
  premierCoverFeeLimit: undefined,
  isAnnualPayment: false,
  additionalQuestionCheapestPremier: undefined,
  additionalQuestionStandard: undefined,
};

export const UPDATE_QUOTE_OPTIONS = 'UPDATE_QUOTE_OPTIONS';

export type UpdateQuoteOptionsAction = {
  type: typeof UPDATE_QUOTE_OPTIONS;
  update: QuoteOptions;
};
