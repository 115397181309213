import { Bundle, Quote } from 'apiHelpers/quote/quoteResponse';
import flatMap from 'lodash/flatMap';
import {
  CORE_COVER,
  CoverLevel,
  VetBillsAccidentsAndIllness,
} from 'helpers/businessConstants';
import {
  AccidentAndIllnessCoverDuration,
  CoverType,
  ExtraCoverFeeLimit,
  initialQuoteOptions,
  PremierCoverFeeLimit,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { QuoteCover, RequoteParams } from './quoteRequest';

export const hasUserSelectedCover = (quote: Quote): boolean =>
  !!quote.petInfos[0]?.userSelectedCover;

export const getQuoteOptionsFromCoverLevel = (coverLevel: CoverLevel): QuoteOptions => {
  const extraQuoteOptions: QuoteOptions = {
    ...initialQuoteOptions,
    coverType: CoverType.Accident_And_Illness,
    accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration.Until_Limit,
  };

  const premierQuoteOptions: QuoteOptions = {
    ...initialQuoteOptions,
    coverType: CoverType.Accident_And_Illness,
    accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration.Ongoing,
  };

  switch (coverLevel) {
    case CoverLevel.ACCIDENT_AND_INJURY:
      return {
        ...initialQuoteOptions,
        coverType: CoverType.Accidents_Only,
      };

    case CoverLevel.STANDARD:
      return {
        ...initialQuoteOptions,
        coverType: CoverType.Accident_And_Illness,
        accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration.Short_Term,
      };

    case CoverLevel.EXTRA_4000:
      return {
        ...extraQuoteOptions,
        extraCoverFeeLimit: ExtraCoverFeeLimit.Limit_4000,
      };
    case CoverLevel.EXTRA_7500:
      return {
        ...extraQuoteOptions,
        extraCoverFeeLimit: ExtraCoverFeeLimit.Limit_7500,
      };

    case CoverLevel.PREMIER_2000:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_2000,
      };
    case CoverLevel.PREMIER_4000:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_4000,
      };
    case CoverLevel.PREMIER_7500:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_7500,
      };
    case CoverLevel.PREMIER_10000:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_10000,
      };

    default:
      return { ...initialQuoteOptions };
  }
};

export const getQuoteOptionsFromQuote = (quote: Quote): QuoteOptions => {
  if (!hasUserSelectedCover(quote)) {
    return initialQuoteOptions;
  }
  const coverLevel = quote.petInfos[0].coverLevelRequired;
  return getQuoteOptionsFromCoverLevel(coverLevel);
};

/**
 * Gets the bundle on the quote associated with the cover level supplied.
 * Note that this assumes there is only one version of each bundle in the quote.
 *
 * @param coverLevel The cover level to find the bundle for
 * @param quote The quote, as returned from the API
 * @returns The bundle associated with the supplied cover level
 */
export const getBundleFromQuote = (
  coverLevel: CoverLevel,
  quote: Quote
): Bundle | undefined => {
  if (coverLevel === CoverLevel.NO_COVER_SELECTED) {
    return undefined;
  }

  const allBundles = flatMap(
    quote.premium.bundleContainers,
    (container) => container.bundles
  );

  return allBundles.find((bundle) => bundle.coverLevelRequired === coverLevel);
};

export const getCoverLevelFromQuoteOptions = (quoteOptions: QuoteOptions): CoverLevel => {
  const {
    coverType,
    accidentAndIllnessCoverDuration,
    extraCoverFeeLimit,
    premierCoverFeeLimit,
  } = quoteOptions;

  if (coverType === CoverType.Accidents_Only) {
    return CoverLevel.ACCIDENT_AND_INJURY;
  }

  if (accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Short_Term) {
    return CoverLevel.STANDARD;
  }

  if (
    accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Until_Limit &&
    extraCoverFeeLimit
  ) {
    switch (extraCoverFeeLimit) {
      case ExtraCoverFeeLimit.Limit_4000:
        return CoverLevel.EXTRA_4000;
      case ExtraCoverFeeLimit.Limit_7500:
      default:
        return CoverLevel.EXTRA_7500;
    }
  }

  if (
    accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Ongoing &&
    premierCoverFeeLimit
  ) {
    switch (premierCoverFeeLimit) {
      case PremierCoverFeeLimit.Limit_2000:
        return CoverLevel.PREMIER_2000;
      case PremierCoverFeeLimit.Limit_4000:
        return CoverLevel.PREMIER_4000;
      case PremierCoverFeeLimit.Limit_7500:
        return CoverLevel.PREMIER_7500;
      case PremierCoverFeeLimit.Limit_10000:
      default:
        return CoverLevel.PREMIER_10000;
    }
  }

  return CoverLevel.NO_COVER_SELECTED;
};

/**
 * @param quote The quote being updated
 * @param coverLevel The cover level selected
 * @param coverStartDate (Optional) the start date for the cover, if different to the quote cover start date
 * @returns Array of covers to send for requoting
 */
const getQuoteCoversForCoverLevel = (
  quote: Quote,
  coverLevel: CoverLevel,
  coverStartDate?: string
): QuoteCover[] => {
  const selectedBundle = getBundleFromQuote(coverLevel, quote);

  /* istanbul ignore if */
  if (!selectedBundle) {
    return [];
  }

  /* Cover start dates need to be supplied as an ISO string (i.e. with time component) so we add
    that here manually if using the start date on the quote. We could also use the date on the
    previous quoteCover (it should match), but doing it this way ensures it's definitely correct
    and assumes less of the API! (TODO:EP-199) */
  return quote.covers.map((quoteCover) => ({
    ...quoteCover,
    coverIncludedIndicator:
      !!selectedBundle.covers.find(
        (bundleCover) => bundleCover.coverCode === quoteCover.coverSection
      ) || quoteCover.coreCoverIndicator,
    coverStartDate: coverStartDate ?? `${quote.policyInfo.coverStartDate}T00:00:00`,
  }));
};

export const getCoverLevelFromQuote = (quote: Quote): CoverLevel => {
  const coverLevel = quote.petInfos[0].coverLevelRequired;
  return coverLevel === CoverLevel.INITIAL_VALUE ||
    coverLevel === CoverLevel.NO_COVER_SELECTED
    ? CoverLevel.ACCIDENT_AND_INJURY
    : coverLevel;
};

const mapVetBillsParameters = (
  quoteOptions: QuoteOptions
): {
  vetBillsAccidentsOnly: CoverType;
  vetBillsAccidentsAndIllness: VetBillsAccidentsAndIllness | undefined;
} => {
  if (quoteOptions.coverType === CoverType.Accidents_Only) {
    return {
      vetBillsAccidentsOnly: CoverType.Accidents_Only,
      vetBillsAccidentsAndIllness: undefined,
    };
  }

  if (quoteOptions.coverType === undefined) {
    return {
      vetBillsAccidentsOnly: CoverType.Accident_And_Illness,
      vetBillsAccidentsAndIllness: undefined,
    };
  }

  if (
    !quoteOptions.accidentAndIllnessCoverDuration ||
    quoteOptions.accidentAndIllnessCoverDuration ===
      AccidentAndIllnessCoverDuration.Short_Term
  ) {
    return {
      vetBillsAccidentsOnly: CoverType.Accident_And_Illness,
      vetBillsAccidentsAndIllness: VetBillsAccidentsAndIllness.SHORT_TERM,
    };
  }
  return {
    vetBillsAccidentsOnly: CoverType.Accident_And_Illness,
    vetBillsAccidentsAndIllness: VetBillsAccidentsAndIllness.ONGOING,
  };
};

export const isQuoteOptionSelectionValid = (quoteOptions: QuoteOptions): boolean =>
  getCoverLevelFromQuoteOptions(quoteOptions) !== CoverLevel.NO_COVER_SELECTED;

/**
 * @param quote The quote being updated
 * @param quoteOptions The options the user has selected
 * @param coverStartDate (Optional) the start date for the cover, if different to the quote cover start date*
 * @returns Additional parameters required for the requote request
 *
 * *The start date on the cover objects needs to match the policy start date; the backend does
 * not handle this sensibly by default. In the backend/API sometimes start dates for individual
 * covers are used instead of the actual policy start date, so it's extra important these are in
 * sync!
 */
export const generateRequoteParameters = (
  quote: Quote,
  quoteOptions: QuoteOptions,
  coverStartDate?: string
): RequoteParams => {
  const selectedCoverLevel = getCoverLevelFromQuoteOptions(quoteOptions);
  // The API requires that a cover level is supplied on any requote, even if none selected by user
  const requestCoverLevel =
    selectedCoverLevel === CoverLevel.NO_COVER_SELECTED
      ? CoverLevel.STANDARD
      : selectedCoverLevel;
  return {
    productId: quote.productId,
    coverLevelRequired: requestCoverLevel,
    covers: getQuoteCoversForCoverLevel(quote, requestCoverLevel, coverStartDate),
    isUserSelectedCover: isQuoteOptionSelectionValid(quoteOptions),
    ...mapVetBillsParameters(quoteOptions),
  };
};

/**
 * Gets selected covers that are included in the selected bundle for the quote, excluding the core cover.
 *
 * @param quote
 * @returns array of cover codes included in the quote
 */
export const getSelectedBundleCovers = (quote: Quote): string[] => {
  const coverLevel = getCoverLevelFromQuote(quote);
  const bundle = getBundleFromQuote(coverLevel, quote);

  if (!bundle) {
    return [];
  }

  return flatMap(quote.parentCover, (parentCoverCode) =>
    bundle.covers
      .filter(
        (cover) =>
          parentCoverCode !== CORE_COVER &&
          (cover.coverCode === parentCoverCode || cover.cover.name === parentCoverCode) &&
          cover.selected
      )
      .map((cover) => cover.coverCode)
  );
};
