import CtaBanner from '@rsa-digital/evo-shared-components/components/CtaBanner';
import {
  gridItemPropsOverride,
  GridItemWrapper,
} from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledCtaBanner = styled(CtaBanner)`
  & {
    margin: ${spacing(4)} 0;

    ${GridItemWrapper} {
      ${gridItemPropsOverride({ desktop: 12, tabletLandscape: 12 })}
    }
  }
`;
