import { AxiosError } from 'axios';
import { isIneligibleQuoteError } from 'businessLogic/errors/isIneligibleQuoteError';
import { isMinMaxError } from 'businessLogic/errors/isMinMaxError';
import { isQuoteConvertedToPolicyError } from 'businessLogic/errors/isQuoteConvertedError';
import { isQuoteExpiredError } from 'businessLogic/errors/isQuoteExpiredError';
import { isQuoteNotFoundError } from 'businessLogic/errors/isQuoteNotFoundError';
import { isReCaptchaThresholdError } from 'businessLogic/errors/recaptchaError';
import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import { isAxiosError } from './axiosResponseHelpers';
import { trackAPIError, trackReCaptchaScore } from './eventTracking';

/**
 * A utility hook for commonising default error handling (i.e. logging the error and setting the
 * appropriate state in redux, to be handled by the error boundary). For API errors with a response
 * status, the status is passed through.
 *
 * @returns a function which takes the error and updates redux appropriately
 *
 * @example
 * const defaultErrorHandling = useDefaultErrorHandler();
 * ...
 * const doSomething = () => {
 *   try {
 *     // do the thing which might throw
 *   } catch (error) {
 *     // special casing first (if applicable)
 *     if (isAxiosError(error) && error.response?.status === 403) {
 *       // handle special case
 *     } else {
 *       defaultErrorHandling(error);
 *     }
 *   }
 * }
 */
const useDefaultErrorHandling = (): ((error: Error | AxiosError) => void) => {
  const dispatchError: Dispatch<ErrorAction> = useDispatch();
  return (error: Error | AxiosError): void => {
    trackAPIError(error);
    if (isAxiosError(error)) {
      if (isIneligibleQuoteError(error)) {
        dispatchError({
          type: ERROR,
          errorType: ErrorType.QUOTE_INELIGIBLE,
          statusCode: error.response?.status,
        });
      } else if (isMinMaxError(error)) {
        dispatchError({
          type: ERROR,
          errorType: ErrorType.MIN_MAX_ERROR,
          statusCode: error.response?.status,
        });
      } else if (isQuoteExpiredError(error)) {
        dispatchError({
          type: ERROR,
          errorType: ErrorType.QUOTE_EXPIRED,
          statusCode: error.response?.status,
        });
      } else if (isQuoteConvertedToPolicyError(error)) {
        dispatchError({
          type: ERROR,
          errorType: ErrorType.QUOTE_CONVERTED,
          statusCode: error.response?.status,
        });
      } else if (isQuoteNotFoundError(error)) {
        dispatchError({
          type: ERROR,
          errorType: ErrorType.QUOTE_NOT_FOUND,
          statusCode: error.response?.status,
        });
      } else if (isReCaptchaThresholdError(error)) {
        trackReCaptchaScore(false, error.response.data.Details.recaptchaScore);
        dispatchError({
          type: ERROR,
          errorType: ErrorType.RECAPTCHA_ERROR,
        });
      } else if (error.response) {
        console.error(
          `Error when making API request: server returned ${error.response.status}`,
          ...(error.response.data ? [' with data ', error.response.data] : [])
        );
        dispatchError({
          type: ERROR,
          errorType: ErrorType.API_ERROR,
          statusCode: error.response.status,
        });
      } else {
        console.error(`Error when making API request: ${error.message ?? ''}`);
        dispatchError({
          type: ERROR,
          errorType: ErrorType.API_ERROR,
        });
      }
      return;
    }
    console.error('Error', error.message);
    dispatchError({
      type: ERROR,
      errorType: ErrorType.UNKNOWN,
    });
  };
};

export default useDefaultErrorHandling;
