import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addOrdinalSuffix,
  longMonth,
  numericYear,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';

export const getDateOnlyIsoString = (dateIsoString: string): string =>
  dateIsoString.split('T')[0];

/**
 * Formats to a date like `1st October 2017` or `1st, October 2017`
 */
export const formatLongDateWithOrdinalSuffix = (
  date: Date | string,
  formatWithComma = false
): string => {
  const parsedDate = parseDate(date);

  return `${addOrdinalSuffix(parsedDate.getDate())}${
    formatWithComma ? ',' : ''
  } ${longMonth.format(parsedDate)} ${numericYear.format(parsedDate)}`;
};

export const areDateValuesEqual = (
  dateValue1: DateValue,
  dateValue2: DateValue
): boolean => {
  return (
    dateValue1.day === dateValue2.day &&
    dateValue1.month === dateValue2.month &&
    dateValue1.year === dateValue2.year
  );
};

export const isDateValid = (date: Date): boolean => {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(+date);
};

export const getDifferenceInDates = (
  dateValue1: string | Date | undefined = new Date(),
  dateValue2: string | Date | undefined = new Date()
): number => {
  const date1 = new Date(dateValue1);
  const date2 = new Date(dateValue2);

  if (new Date(date1) > new Date(date2)) return 0;

  return Math.ceil(
    (new Date(date2).getTime() - new Date(date1).getTime()) / (1000 * 60 * 60 * 24)
  );
};
