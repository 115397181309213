import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import {
  CallToAction,
  TextLink,
} from '@rsa-digital/evo-shared-components/commonTypes/links';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { MouseEventHandler } from 'react';
import { TableProps } from 'components/Table';
import { CsAsset, CsCta, CsLink, CsTable } from 'types/contentStack';
import { CsBlock } from './blockMapper';
import { buildWarning } from './errorReporting';
import { PageTitle } from './eventTracking';
import { handleAndTrackLinkClick } from './navigation';

/**
 * Returns the single element in the array if it exists, otherwise returns undefined
 *
 * For certain fields in contentstack, if the user leaves it blank then we get an
 * empty array, and when filled, we get a singleton array. One such field is the
 * reference field. We can use this method to process those cases.
 */
export const unwrapSingleton = <T>(singletonOrEmptyArray: [T] | []): T | undefined =>
  first(singletonOrEmptyArray);

/**
 * Maps the custom table schema in Contentstack to the format accepted by the Table component.
 */
export const mapCustomTable = (table: CsTable): TableProps => ({
  headerRow:
    table.header_row.header_cells.length > 0
      ? {
          cornerCellGapColSpan: table.header_row.has_empty_corner_cell
            ? table.header_row.corner_cell_column_span ?? 1
            : undefined,
          cells: table.header_row.header_cells.map((cell) => ({
            text: cell.heading_text,
            colSpan: cell.column_span,
            highlight: cell.is_highlighted,
          })),
        }
      : undefined,
  bodyRows: table.content_rows.map((row) => ({
    cells: row.content_row.cells.map((cell) => ({
      content: cell.cell.content_rich_text,
      colSpan: cell.cell.column_span,
      highlight: cell.cell.is_highlighted,
      isHeader: cell.cell.is_header_cell,
    })),
  })),
});

/**
 * Returns undefined if the object if contains no truthy values,
 * otherwise returns the original object.
 *
 * For optional fields in contentstack that contain multiple fields, if
 * the user leaves the types blank then we get an object with empty values,
 * rather than null/undefined. For example, this occurs when dealing with
 * the 'link' type being used for optional CTAs. We can use this method
 * to process those cases.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const undefinedIfEmpty = <T extends Record<string, any>>(
  csObject: T | null
): T | undefined => {
  if (csObject != null && Object.values(csObject).some((v) => !!v)) {
    return csObject;
  }
  return undefined;
};

type Asset = {
  publicUrl: string;
  filename?: string;
  description?: string;
};

const processAsset = (csAsset: CsAsset | null): Asset | undefined => {
  const asset = undefinedIfEmpty(csAsset);

  /* istanbul ignore if */
  if (asset && !asset.description) {
    buildWarning(`Asset with filename "${asset.filename}" is missing a description`);
  }

  return asset
    ? {
        publicUrl: asset.localAsset?.publicURL,
        filename: asset.filename ?? /* istanbul ignore next */ undefined,
        description: asset.description ?? /* istanbul ignore next */ undefined,
      }
    : undefined;
};

export const processImageAsset = (csAsset: CsAsset | null): Image | undefined => {
  const asset = processAsset(csAsset);

  return asset
    ? {
        url: asset.publicUrl,
        altText: asset.description ?? /* istanbul ignore next */ undefined,
      }
    : undefined;
};

export const processOptionalImageAsset = (
  csAsset: CsAsset | undefined | null
): Image | undefined => (csAsset ? processImageAsset(csAsset) : undefined);

const processCta = (
  cta: CsCta,
  pageTitle: PageTitle,
  onClick?: MouseEventHandler
): CallToAction => ({
  displayText: cta.display_text,
  screenReaderText: cta.screen_reader_text,
  url: cta.url,
  onClick: (event) => {
    onClick?.(event);
    handleAndTrackLinkClick(cta.url, pageTitle, cta.display_text, event);
  },
});

export const processOptionalCta = (
  maybeCta: [CsCta] | [] | null,
  pageTitle: PageTitle,
  onClick?: () => void
): CallToAction | undefined => {
  if (isEmpty(maybeCta)) {
    return undefined;
  }

  const cta = maybeCta?.[0] as CsCta;
  return processCta(cta, pageTitle, onClick);
};

export const processMandatoryCta = (
  cta: [CsCta],
  pageTitle: PageTitle,
  onClick?: () => void
): CallToAction => {
  const result = processOptionalCta(cta, pageTitle, onClick);
  /* istanbul ignore next */
  if (result === undefined) {
    throw new Error('Missing CTA field');
  }
  return result;
};

export const processTextLink = (link: CsLink | null): TextLink | undefined => {
  if (!link || !link.href || !link.title) {
    return undefined;
  }

  return {
    text: link.title,
    url: link.href,
  };
};

export const replaceBlockRichTextPlaceholders = (
  block: CsBlock,
  replaceRichTextPlaceholder: (csRichTextString: string) => string
): CsBlock => {
  if (block.feature) {
    return {
      feature: {
        ...block.feature,
        body: replaceRichTextPlaceholder(block.feature.body),
      },
    };
  }

  return block;
};
