import useGenerateQuote from 'apiHelpers/quote/useGenerateQuote';
import quoteClient, { DeeplinkRequest } from 'apiHelpers/quoteClient';
import { PetCoverLevelReferenceData } from 'apiHelpers/referenceData/petCoverLevel';
import { shouldAggsQuoteShowAdditionalQuestions } from 'businessLogic/aggregators';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useEffect, useReducer } from 'react';
import Layout from 'components/Layout';
import LoadingAnimation from 'components/QuoteGenerating/LoadingAnimation';
import { CoverLevel } from 'helpers/businessConstants';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackDeeplinkReferral } from 'helpers/eventTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import { useInitialiseQuoteWithCoverLevel } from 'state/quote/loadQuoteHelper';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsAsset } from 'types/contentStack';

const STEP = 2;

type LoadingQuoteLocation = Location & {
  state?: {
    shouldNotInvalidateAssumptions?: boolean;
  };
};

type LoadingQuoteProps = {
  data: {
    csPetLoadingPage: {
      meta_title: string;
      heading: string;
      subheading: string;
      animation: CsAsset;
    };
  };
  location: LoadingQuoteLocation;
};

export const query = graphql`
  query {
    csPetLoadingPage {
      meta_title
      heading
      subheading
      animation {
        ...CsAsset
      }
    }
  }
`;

export const getDeepLinkParams = (location: Location): DeeplinkRequest | null => {
  const quoteNumber = getQueryParam(location, 'qn');
  const postcode = getQueryParam(location, 'pc');
  const checksum = getQueryParam(location, 'checksum');
  const msmUrn = getQueryParam(location, 'URN');
  const msmSsid = getQueryParam(location, 'ssid');
  const gocoUuid = getQueryParam(location, 'convert_uuid');
  const referralRouteId = getQueryParam(location, 'referralRouteId');

  if (!quoteNumber || !postcode || !checksum) {
    return null;
  }

  return {
    qn: quoteNumber,
    pc: postcode,
    checksum,
    msmUrn: msmUrn ?? undefined,
    msmSsid: msmSsid ?? undefined,
    gocoUuid: gocoUuid ?? undefined,
    referralRouteId: referralRouteId ?? undefined,
  };
};

const getCoverLevelFromQueryParams = (
  location: Location,
  coverLevels: PetCoverLevelReferenceData
): CoverLevel => {
  // The tier gotten from the aggregator doesn't always match exactly with the reference data, but they only differ
  // by non alphanumeric characters, so we remove any non alphanumeric characters before comparing.
  const invalidRegex = new RegExp('[^A-Za-z0-9]', 'g');
  const aggregatorCoverLevel = getQueryParam(location, 'tier')?.replace(invalidRegex, '');

  if (!aggregatorCoverLevel) {
    return CoverLevel.NO_COVER_SELECTED;
  }

  // The tier param is sometimes sent with a &, so the full value isn't picked up and needs to be checked manually
  if (aggregatorCoverLevel === 'Accident') {
    return CoverLevel.ACCIDENT_AND_INJURY;
  }

  return (coverLevels.petCoverLevel.find(
    (level) => level.name.replace(invalidRegex, '') === aggregatorCoverLevel
  )?.value || CoverLevel.NO_COVER_SELECTED) as CoverLevel;
};

const LoadingQuote: React.FC<LoadingQuoteProps> = ({
  data: {
    csPetLoadingPage: { heading, subheading, animation, meta_title },
  },
  location,
}) => {
  const initialiseQuoteWithCoverLevel = useInitialiseQuoteWithCoverLevel();
  const defaultErrorHandling = useDefaultErrorHandling();
  const [requestStarted, setRequestStarted] = useReducer(() => true, false);

  const { createQuote } = useGenerateQuote(
    location.state?.shouldNotInvalidateAssumptions
  );

  const disableDateChecks = useDisableDateChecks();
  const petCoverLevels = useReferenceData('petCoverLevel');

  const fetchQuoteAndMoveNext = useCallback(async (): Promise<void> => {
    try {
      const deepLinkParams = getDeepLinkParams(location);
      if (deepLinkParams) {
        if (!petCoverLevels) {
          return;
        }
        setRequestStarted();
        const quoteResponse = await quoteClient.deeplink({
          ...deepLinkParams,
          disableDateChecks,
        });
        await initialiseQuoteWithCoverLevel(
          quoteResponse,
          getCoverLevelFromQueryParams(location, petCoverLevels)
        );
        if (shouldAggsQuoteShowAdditionalQuestions(quoteResponse)) {
          navigate(quoteAndBuyRoutes.additionalQuestions, { replace: true });
        } else {
          navigate(quoteAndBuyRoutes.quoteSummary, { replace: true });
        }
      } else {
        setRequestStarted();
        await createQuote();
        navigate(quoteAndBuyRoutes.quoteSummary, { replace: true });
      }
    } catch (err) {
      const error: Error = err as Error;
      defaultErrorHandling(error);
    }
  }, [
    createQuote,
    defaultErrorHandling,
    disableDateChecks,
    initialiseQuoteWithCoverLevel,
    location,
    petCoverLevels,
  ]);

  useEffect(() => {
    if (getDeepLinkParams(location)) {
      trackDeeplinkReferral(document.referrer);
    }
  }, [location]);

  useEffect(() => {
    if (!requestStarted) {
      fetchQuoteAndMoveNext();
    }
  }, [fetchQuoteAndMoveNext, requestStarted]);

  const quote = useCurrentQuote();
  usePageTracking(meta_title);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking();
  useCheckoutTracking(STEP, quote, true);

  useEffect(() => {
    const unloadCallback = (event: {
      preventDefault: () => void;
      returnValue: string;
    }): string => {
      event.preventDefault();
      const ev = event;
      ev.returnValue =
        'Refreshing the page may cause you to lose your data. Are you sure you want to refresh?';
      return event.returnValue;
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <Layout pageTitle={PageTitle.QuoteGenerating} metaTitle={meta_title}>
      <LoadingAnimation heading={heading} subheading={subheading} animation={animation} />
    </Layout>
  );
};

export default LoadingQuote;
