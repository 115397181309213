import Image from '@rsa-digital/evo-shared-components/components/Image';
import { ImageStyle } from '@rsa-digital/evo-shared-components/components/Image/styles';
import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Hero = styled.h1`
  ${fonts.headingLarge}
  && {
    color: ${colors.core01};
  }
  margin: 0;
  padding-bottom: ${spacing(4)};
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing(2)};
  padding-top: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
    padding-top: ${spacing(3)};  
  `}
`;

export const StyledImage = styled(Image)`
  margin: -${spacing(1)} 0 ${spacing(4)} 0;
  && {
    height: ${spacing(5)};
    width: auto;
  }
`;

export const BackgroundContainer = styled(PageWidthContainer)`
  background-color: ${colors.neutral08};

  & > ${ImageWrapper} + ${Hero} {
    margin-top: ${spacing(5.5)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(2.5)}
    `}

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(3.5)};
    `}
  }
`;

export const SizedImage = styled(Image)<{ removeLinkPadding: boolean }>`
  ${ImageStyle} {
    width: auto;
    padding: ${spacing(0.5)};
    height: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
      height: ${spacing(5)};
    `}
  }
  width: auto;
  padding: ${(p) => (p.removeLinkPadding ? 0 : spacing(0.5))};
  height: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    height: ${spacing(5)};
  `}
`;

export const Subheading = styled.p`
  ${fonts.paragraphLarge}
  margin: ${spacing(-2)} 0 0;
  padding-bottom: ${spacing(3)};
  && {
    color: ${colors.core01};
  }

  ${mediaQuery.tabletPortrait`
    padding-bottom: ${spacing(4)};
  `}
`;

export const StyledRichText = styled(RichText)`
  ${fonts.paragraphLarge}
  margin: ${spacing(-2)} 0 0;
  padding-bottom: ${spacing(3)};
  && {
    color: ${colors.core01};
  }

  ${mediaQuery.tabletPortrait`
    padding-bottom: ${spacing(4)};
  `}
`;
