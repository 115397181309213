import { ClubcardData } from 'apiHelpers/clubcard/getClubcardDataFromQuote';
import { FullQuoteFormData } from 'state/formData/quoteInProgress';
import { Quote } from './quoteResponse';
import mapCustomerDetails from './quoteToFormMappings/mapCustomerDetails';
import mapJointPolicyholderDetails from './quoteToFormMappings/mapJointPolicyholderDetails';
import mapPetsDetails from './quoteToFormMappings/mapPetsDetails';
import mapPolicyDetails from './quoteToFormMappings/mapPolicyDetails';

const mapQuoteToForm = (quote: Quote, clubcardData: ClubcardData): FullQuoteFormData => ({
  customerDetails: mapCustomerDetails(quote.customerInfo, quote.policyInfo, clubcardData),
  petsDetails: mapPetsDetails(quote.petInfos),
  jointPolicyholderDetails: mapJointPolicyholderDetails(quote.policyInfo),
  policyDetails: mapPolicyDetails(quote.policyInfo),
});

export default mapQuoteToForm;
