import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { getInferedGenderFromTitle } from 'helpers/genderHelpers';
import { maritalStatus_NOT_NEEDED } from 'helpers/referenceDataConstants';
import { CustomerDetails } from 'state/formData/customerDetails';
import mapAddress from './mapAddress';
import mapAddressLookup from './mapAddressLookup';
import { QuoteCustomerInfo } from '../quoteRequest';

export const mapClubcardInfo = (
  customerDetails: CustomerDetails,
  isAggsQuote: boolean
): { clubcardNumber: string | null; isStaff: boolean | null; staffId: string | null } => {
  if (customerDetails.clubcardLookup.status === LookupStatus.Success) {
    const isStaff =
      customerDetails.clubcardLookup.data.isStaff &&
      !!customerDetails.hasColleagueClubcard &&
      !isAggsQuote;
    return {
      clubcardNumber: customerDetails.clubcardLookup.lookupKey,
      isStaff,
      staffId: isStaff ? customerDetails.employeeNumber.number : null,
    };
  }
  if (customerDetails.clubcardSearch.status === 'SUCCESS') {
    const isStaff =
      customerDetails.clubcardSearch.data.isStaff &&
      !!customerDetails.hasColleagueClubcard;
    return {
      clubcardNumber: customerDetails.clubcardSearch.data.clubcardNumber,
      isStaff,
      staffId: isStaff ? customerDetails.employeeNumber.number : null,
    };
  }
  return {
    clubcardNumber: null,
    isStaff: null,
    staffId: null,
  };
};

const mapCustomerInfo = (
  customerDetails: CustomerDetails,
  isAggsQuote: boolean
): QuoteCustomerInfo => ({
  title: customerDetails.customerTitle,
  firstName: customerDetails.customerFirstName,
  lastName: customerDetails.customerLastName,
  gender: getInferedGenderFromTitle(customerDetails.customerTitle),
  dob: dateValueToISODateString(customerDetails.customerDob) || '',
  email: customerDetails.customerEmail,
  contactPhoneNumber: customerDetails.customerTelephone,
  address: customerDetails.isManualAddress
    ? mapAddress(customerDetails)
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      mapAddressLookup(customerDetails.address!),
  ...mapClubcardInfo(customerDetails, isAggsQuote),
  maritalStatus: maritalStatus_NOT_NEEDED,
});

export default mapCustomerInfo;
