import { Quote } from 'apiHelpers/quote/quoteResponse';
import {
  AccidentAndIllnessCoverDuration,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { CsApplicableProducts } from 'types/contentStack';
import { ProductId } from './businessConstants';
import { CurrentQuote } from './useCurrentQuote';

// Note this *MUST* match the strings in the 'Product' global field in CS
export enum Product {
  Accident_And_Injury = 'Accident only',
  Standard = 'Standard',
  Extra = 'Extra',
  Premier = 'Premier',
}

export const getProductFromQuoteOptions = (
  quoteOptions: QuoteOptions
): Product | null => {
  switch (quoteOptions.accidentAndIllnessCoverDuration) {
    case AccidentAndIllnessCoverDuration.Short_Term:
      return Product.Standard;
    case AccidentAndIllnessCoverDuration.Until_Limit:
      return Product.Extra;
    case AccidentAndIllnessCoverDuration.Ongoing:
      return Product.Premier;
    default:
      return null;
  }
};

export const appliesToSelection = (
  quoteOptions: QuoteOptions,
  csProductSelection: CsApplicableProducts
): boolean => {
  const product = getProductFromQuoteOptions(quoteOptions);
  if (!product) {
    return false;
  }
  return csProductSelection.product_selector.includes(product);
};

export const isQuoteFromAggs = (quote: CurrentQuote | Quote | undefined): boolean => {
  return quote ? quote.productId !== ProductId.DIRECT : false;
};

export const isCoinsuranceQuote = true;
