import {
  Age8ExcessAgreements,
  initialAge8ExcessAgreements,
  UPDATE_AGE_8_EXCESS_AGREEMENTS,
  UpdateAge8ExcessAgreementsAction,
} from './Age8ExcessAgreements';

const Age8ExcessAgreementsReducer = (
  age8ExcessAgreements: Age8ExcessAgreements = initialAge8ExcessAgreements,
  action: UpdateAge8ExcessAgreementsAction
): Age8ExcessAgreements => {
  switch (action.type) {
    case UPDATE_AGE_8_EXCESS_AGREEMENTS:
      return {
        ...age8ExcessAgreements,
        ...action.update,
      };
    default:
      return age8ExcessAgreements;
  }
};

export default Age8ExcessAgreementsReducer;
