import {
  InnerSection,
  SectionButton,
} from '@rsa-digital/evo-shared-components/components/Accordion/InnerAccordionSection/styles';
import SecondaryAccordion from '@rsa-digital/evo-shared-components/components/Accordion/SecondaryAccordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { TableCell } from '@rsa-digital/evo-shared-components/components/Table';
import { StyledTableHeaderCell } from '@rsa-digital/evo-shared-components/components/Table/TableCell/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/Tesco/colors';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled, { css } from 'styled-components';

const cellStyling = css`
    ${fonts.paragraph}
    padding-left: ${spacing(1)};
    padding-right: ${spacing(1)};

    ${mediaQuery.tabletPortrait`
      padding: ${spacing(1.5)} ${spacing(2)};
    `}
`;

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};
  margin-bottom: ${spacing(3)};
  margin-top: ${spacing(2)};

  ${mediaQuery.desktop`
    margin-top: ${spacing(3.5)};
    margin-bottom: ${spacing(4)};
  `}
`;

export const TableWrapper = styled.div`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)};
  `}

  ${StyledTableHeaderCell} {
    ${cellStyling}
   height: ${spacing(7.5)};
  & {
    background: ${colors.core01};
    div {
      font-weight: 700;
    }
  }
  }
`;

export const TableTitle = styled.div`
  ${fonts.paragraphLarge}
  && {
    color: ${colors.core02};
    font-weight: ${heavyFontWeight};
    line-height: 1.75rem;
  }
`;
export const TableSubTitle = styled.p`
  font-size: ${spacing(2)};
  margin: ${spacing(1)};
  && {
    color: ${colors.core02};
    font-weight: 300;
    line-height: 1.5rem;
  }
  ${mediaQuery.desktop`
  ${fonts.paragraph}; 
  `}
  ${mediaQuery.tabletLandscape`
    ${fonts.paragraph};
  `}
  ${mediaQuery.tabletPortrait`
    ${fonts.paragraph};
  `}
`;

export const PaddedTableCell = styled(TableCell)`
  ${cellStyling}
  height: ${spacing(7)};
  width: 33.33%;
`;

export const ColumnHeadingCell = styled(TableCell)`
  ${cellStyling}
  && {
    text-align: center;
    font-weight: ${heavyFontWeight};
    height: ${spacing(7)};
    background: ${colors.core02};
  }
`;

export const RowHeadingCell = styled(TableCell)`
  ${cellStyling}
  & {
    text-align: center;
    font-weight: ${heavyFontWeight};
  }
  div {
    word-break: break-word;
  }

  ${mediaQuery.tabletPortrait`
    div {
      word-break: normal;
    }
  `}
`;

export const MobileAccordion = styled(SecondaryAccordion)`
  margin-bottom: ${spacing(2)};
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(1)};
    margin-bottom: ${spacing(1)};
  `}

  ${mediaQuery.desktop`
    margin-top: 0;
    display: none;
  `}

  table {
    table-layout: fixed;
    margin-bottom: ${spacing(2)};
  }
  ${InnerSection} {
    border: 0 none;
    padding-bottom: 0;
    ${SectionButton} {
      & {
        padding-bottom: ${spacing(1.5)};
        border-bottom: 1px solid ${colors.neutral04};
      }
    }
  }
`;

export const DesktopAccordion = styled(SecondaryAccordion)`
  display: none;

  ${mediaQuery.desktop`
    display: flex;
  `}

  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(1)};

  ${TableWrapper} {
    display: flex;
    flex-direction: column;
  }

  table {
    table-layout: auto;
    margin-bottom: ${spacing(3)};
    max-width: ${spacing(68)};
  }
  table:last-child {
    margin-bottom: 0;
  }

  tbody td {
    div {
      min-height: 1.8rem;
      align-items: center;
    }
  }
  ${InnerSection} {
    border: 0 none;
    padding-bottom: 0;
    ${SectionButton} {
      & {
        padding-bottom: ${spacing(1.5)};
        border-bottom : 1px solid ${colors.neutral04};
      }
    }
  }
`;

export const GridWithBottomMargin = styled(Grid)`
  & {
    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(3)};
    `}

    ${mediaQuery.desktop`
      margin-bottom: ${spacing(4)};
    `}
  }
`;
