import { Quote } from 'apiHelpers/quote/quoteResponse';
import {
  QUOTE_UUID_SESSION_KEY,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { petApiService } from './apiService';
import { cleanRequoteRequest } from './quote/cleanRequoteRequest';
import { QuoteRequest, RequoteRequest } from './quote/quoteRequest';

export type QuoteSearchRequest = {
  firstForename: string;
  lastname: string;
  birthdate: string;
  postcode: string;
};

type QuoteRetrieveRequest = {
  postcode: string;
  quoteNumber: string;
  disableDateChecks?: boolean;
  includePastCoverStartDates?: boolean;
  recaptchaToken?: string;
  recaptchaSkipKey?: string;
};

export type DeeplinkRequest = {
  pc: string;
  qn: string;
  checksum: string;
  msmUrn?: string;
  msmSsid?: string;
  gocoUuid?: string;
  referralRouteId?: string;
  disableDateChecks?: boolean;
};

type QuoteSearchResponse = {
  quoteNumber: string;
  quoteStartDate: string;
};

type QuoteClient = {
  createQuote: (request: QuoteRequest) => Promise<Quote>;
  requote: (request: RequoteRequest) => Promise<Quote>;
  getSessionQuote: () => Promise<Quote>;
  retrieve: (request: QuoteRetrieveRequest) => Promise<Quote>;
  search: (request: QuoteSearchRequest) => Promise<QuoteSearchResponse[]>;
  deeplink: (request: DeeplinkRequest) => Promise<Quote>;
};

const quoteClient: QuoteClient = {
  createQuote: async (request: QuoteRequest) => {
    const quote = await petApiService.post<Quote>('quote', request);
    storeData(QUOTE_UUID_SESSION_KEY, quote.uuid);
    return quote;
  },
  requote: (request: RequoteRequest) => {
    const uuid = retrieveData(QUOTE_UUID_SESSION_KEY);
    return petApiService.post<Quote>('requote', cleanRequoteRequest(request), { uuid });
  },
  getSessionQuote: () => {
    const quoteUuid = retrieveData(QUOTE_UUID_SESSION_KEY);
    return petApiService.get('quote/current', { uuid: quoteUuid });
  },
  retrieve: async (request) => {
    const quote = await petApiService.post<Quote>('quote/summary', request);
    storeData(QUOTE_UUID_SESSION_KEY, quote.uuid);
    return quote;
  },
  search: (request: QuoteSearchRequest) => petApiService.post('search', request),
  deeplink: async (request: DeeplinkRequest) => {
    const quote = await petApiService.get<Quote>('deeplink', request);
    storeData(QUOTE_UUID_SESSION_KEY, quote.uuid);
    return quote;
  },
};

export default quoteClient;
