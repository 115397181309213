import { ReasonToChoose } from '@rsa-digital/evo-shared-components/components/WhyChooseUs';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';
import { WhyChooseUsGrid } from './styles';

type CsReasonToChoose = {
  additional_info: string;
  explanation: string;
  reason_heading: string;
  image: CsAsset | null;
};

type CsWhyChooseUs = {
  csPetWhyChooseUs: {
    why_choose_us: {
      heading: string;
      reason: CsReasonToChoose[];
    };
  };
};

const query = graphql`
  query {
    csPetWhyChooseUs {
      why_choose_us {
        reason {
          additional_info
          explanation
          reason_heading
          image {
            ...CsAsset
          }
        }
      }
    }
  }
`;

const WhyChooseUsSection: React.FC<{
  pageTitle: PageTitle;
  desktop?: number;
  tabletLandscape?: number;
}> = ({ pageTitle, desktop, tabletLandscape }) => {
  const {
    csPetWhyChooseUs: {
      why_choose_us: { heading, reason: reasons },
    },
  } = useStaticQuery<CsWhyChooseUs>(query);

  const processedReasons: ReasonToChoose[] = reasons.map((reason) => ({
    ...reason,
    image: processImageAsset(reason.image),
  }));

  return (
    <WhyChooseUsGrid
      title={heading}
      reasonsToChoose={processedReasons}
      onLinkClick={trackRichTextLinkClick(pageTitle)}
      data-cy="why_choose_us"
      gridItemProps={{ desktop, tabletLandscape }}
    />
  );
};

export default WhyChooseUsSection;
