import { graphql, useStaticQuery } from 'gatsby';

type KickoutMessageText = {
  csPetGlobalConfig: {
    quote_kick_out_message_rich_text: string;
  };
};

const kickoutMessageTextQuery = graphql`
  query {
    csPetGlobalConfig {
      quote_kick_out_message_rich_text
    }
  }
`;

const useKickoutMessageText = (): string => {
  return useStaticQuery<KickoutMessageText>(kickoutMessageTextQuery).csPetGlobalConfig
    .quote_kick_out_message_rich_text;
};
export default useKickoutMessageText;
