export const CLUBCARD_NUMBER_PREFIX = '63400';

export const CORE_COVER = 'NWA_PET_9382';

export const AGE_8_EXCESS_PERCENTAGE = 20;

export enum CoverLevel {
  ACCIDENT_AND_INJURY = 'NWA_PET_M',
  STANDARD = 'NWA_PET_N',
  EXTRA_4000 = 'NWA_PET_O',
  EXTRA_7500 = 'NWA_PET_Q',
  NO_COVER_SELECTED = 'NWA_PET_UU',
  PREMIER_2000 = 'NWA_PET_I',
  PREMIER_4000 = 'NWA_PET_F',
  PREMIER_7500 = 'NWA_PET_A',
  PREMIER_10000 = 'NWA_PET_B',
  // TODO: EP-638: Remove this once API returns a more sensible initial cover code
  INITIAL_VALUE = 'NWA_PET_04',
}

export enum VetBillsAccidentsAndIllness {
  SHORT_TERM = 'Short_Term_Treatments',
  ONGOING = 'Ongoing_Treatments',
}

export enum VoluntaryExcessAmount {
  EXCESS_60 = '0060',
  EXCESS_120 = '0120',
  EXCESS_200 = '0200',
}

export enum VoluntaryExcessPercentage {
  EXCESS_0_PERCENT = '0000',
  EXCESS_10_PERCENT = '010P',
  EXCESS_20_PERCENT = '020P',
}

export enum ProductId {
  DIRECT = '0000000100',
  GO_COMPARE = '0000000107',
  MONEY_SUPERMARKET = '0000000108',
  COMPARE_THE_MARKET = '0000000111',
}

export enum DesignConstancts {
  PET_BREED_DROPDOWN_MENU_MAX_HEIGHT = '440',
}
