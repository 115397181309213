import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Container = styled.div`
  && > *:first-child {
    margin-top: ${spacing(6)};

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(8)};
    `}
  }

  && > *:last-child {
    margin-bottom: ${spacing(8)};
  }
`;
