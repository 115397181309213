import { FieldErrors } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { isAxiosError } from './axiosResponseHelpers';
import {
  CoverLevel,
  ProductId,
  VoluntaryExcessAmount,
  VoluntaryExcessPercentage,
} from './businessConstants';
import { quoteAndBuyRoutes } from './routingHelper';
import { CurrentQuote } from './useCurrentQuote';
import { version } from '../../package.json';

declare global {
  interface Window {
    dataLayer?: TrackingEvent[];
  }
}

// Max length of a label to include
const MAX_LENGTH = 500;

export enum PageTitle {
  AboutYouAndYourPet = 'About You And Your Pet',
  AdditionalQuestions = 'Additional Questions',
  QuoteSummary = 'Quote Summary',
  Payment = 'Payment',
  RetrieveQuote = 'Retrieve Quote',
  RetrieveQuoteReference = 'Retrieve Quote Reference',
  Confirmation = 'Confirmation',
  CheckYourDetails = 'Check Your Details',
  BusinessError = 'Business Error',
  TechnicalError = 'Technical Error',
  QuoteGenerating = 'Quote Generating',
  SessionEnded = 'Session Ended',
  ConfirmCoverStartDate = 'Confirm Cover Start Date',
  CookiePolicy = 'Cookie Policy',
  ReferAFriend = 'Refer A Friend',
  Referred = 'Referred',
  ContactUsForm = 'Contact Us Form',
  EmailUsForm = 'Email Us Form',
  ContactUs = 'Contact Us',
  CoverStartDate = 'Cover start date',
}

type BaseTrackingEvent = {
  event: string;
  eventCategory?: string | undefined;
  eventAction?: string | undefined;
  eventLabel?: string | undefined;
  apiErrorCode?: string | undefined;
  vpvPath?: string;
  siteVersion?: string;
  isException?: boolean;
  reCaptchaAnswer?: boolean;
  reCaptchaScore?: number;
  statusCode?: number;
  referrer?: string;
};

type PageTrackingEventProps = {
  quoteId?: string;
  quoteAggregatorId?: string;
  policyStartDate?: string;
  coverType?: string;
  petType?: string;
  breedType?: string;
  breed?: string;
  excessAmount?: string;
  quotedPremium?: string;
  petCount?: string;
  pageTitle?: string;
  clubcardCustomer?: boolean;
  promoCode?: string;
  vetFeeExcess?: string;
  additionalExcess?: string;
  additionalQuestionsDisplayed?: string;
  petAge?: string;
  productName?: string;
  vetFeeLimit?: string;
  daysUntilStartDate?: string | number;
  paymentMethod?: string;
};

export type OptanonType = {
  OptanonActiveGroups?: string | undefined;
  OnetrustActiveGroups?: string | undefined;
  event?: string;
};

export type TrackingEvent = BaseTrackingEvent & PageTrackingEventProps & OptanonType;

export const trackEvent = (event: TrackingEvent): void => {
  if (event.eventLabel) {
    // eslint-disable-next-line no-param-reassign
    event.eventLabel = event.eventLabel.substring(0, MAX_LENGTH);
  }
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
};

/* Covering each of these cases is not valuable */
/* istanbul ignore next */
export const getAggregatorFromProductId = (
  productId: string | undefined
): string | undefined => {
  switch (productId) {
    case ProductId.COMPARE_THE_MARKET:
      return 'cm';
    case ProductId.GO_COMPARE:
      return 'gc';
    case ProductId.MONEY_SUPERMARKET:
      return 'ms';
    default:
      return undefined;
  }
};

type DomainAggPairType = { domain: string; aggId: string };

type DomainsToAggsType = {
  staging: DomainAggPairType[];
  production: DomainAggPairType[];
};

const domainsToAggsObject: DomainsToAggsType = {
  staging: [
    { domain: 'stickeedev.com', aggId: 'gc' },
    { domain: 'cytiuat.tech', aggId: 'ms' },
    { domain: 'ctmers.io', aggId: 'cm' },
  ],
  production: [
    { domain: 'gocompare.com', aggId: 'gc' },
    { domain: 'moneysupermarket.com', aggId: 'ms' },
    { domain: 'comparethemarket.com', aggId: 'cm' },
  ],
};

type ReferrerEnvironment = 'staging' | 'production';

export const getAggregatorFromReferrer = (
  referrer: string | undefined
): string | undefined => {
  if (!referrer) return undefined;

  const referrerEnvironment: ReferrerEnvironment =
    process.env.GATSBY_AGGS_REFERRER_ENV === 'staging' ? 'staging' : 'production';

  const domainsToAggsArray = domainsToAggsObject[referrerEnvironment];

  const matchedDomainAggPair = domainsToAggsArray.find((domainAggPair) =>
    referrer.includes(domainAggPair.domain)
  );

  return matchedDomainAggPair?.aggId;
};

/* Covering each of these cases is not valuable */
/* istanbul ignore next */
export const getCoverTypeLabelFromCoverLevel = (coverLevel: CoverLevel): string => {
  switch (coverLevel) {
    case CoverLevel.STANDARD:
      return 'standard';
    case CoverLevel.EXTRA_4000:
    case CoverLevel.EXTRA_7500:
      return 'extra';
    case CoverLevel.PREMIER_2000:
    case CoverLevel.PREMIER_4000:
    case CoverLevel.PREMIER_7500:
    case CoverLevel.PREMIER_10000:
      return 'premier';
    default:
      return 'none selected';
  }
};

export const userHasSelectedCover = (quote: CurrentQuote): boolean =>
  !!quote.petInfos?.[0].userSelectedCover;

export const ANNUAL_PAYMENT_PSEUDO_URL = `${quoteAndBuyRoutes.payment}annual/`;
export const MONTHLY_PAYMENT_PSEUDO_URL = `${quoteAndBuyRoutes.payment}direct-debit/`;

export const trackDeeplinkReferral = (referralUrl: string): void => {
  trackEvent({
    event: 'deeplinkReferral',
    referrer: referralUrl,
  });
};

export const trackErrorPage = (path: string, pageTitle: string): void => {
  trackEvent({
    event: 'newError',
    vpvPath: path,
    pageTitle,
    siteVersion: version,
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  });
};

export const trackTextButtonClick = (pageTitle: PageTitle, buttonText: string): void => {
  trackEvent({
    event: 'buttonClick',
    eventCategory: 'button',
    eventAction: pageTitle,
    eventLabel: buttonText,
  });
};

export const trackPopupTextButtonClick = (
  actionString: string,
  buttonText: string
): void => {
  trackEvent({
    event: 'buttonClick',
    eventCategory: 'pop up click',
    eventAction: actionString,
    eventLabel: buttonText,
  });
};

export const trackFormTextFieldFocus = (label: string) => (): void => {
  trackEvent({
    event: 'formFieldEntry',
    eventCategory: 'form field',
    eventAction: 'Focus',
    eventLabel: label,
  });
};

export const trackRadioButtonClick = (question: string, value: string): void => {
  trackEvent({
    event: 'radioButtonClick',
    eventCategory: 'radio button',
    eventAction: question,
    eventLabel: `${question} - ${value}`,
  });
};

export const trackIconButtonClick = (pageTitle: PageTitle, iconName: string): void => {
  trackEvent({
    event: 'iconClick',
    eventCategory: 'icon',
    eventAction: iconName,
    eventLabel: pageTitle,
  });
};

export const trackFormDropdownSelect = (
  question: string,
  selectedOption?: string
): void => {
  trackEvent({
    event: 'dropdownSelector',
    eventCategory: 'selector dropdown',
    eventAction: question,
    eventLabel: selectedOption,
  });
};

export const trackFormDropdownFocus = (
  question: string,
  pageTitle: PageTitle
) => (): void => {
  trackEvent({
    event: 'primarySelector',
    eventCategory: 'selector primary',
    eventAction: question,
    eventLabel: pageTitle,
  });
};

export const trackTooltipToggle = (pageTitle: PageTitle, label: string): void => {
  trackEvent({
    event: 'tooltipClick',
    eventCategory: 'tooltip',
    eventAction: pageTitle,
    eventLabel: label,
  });
};

export const trackCalendarClick = (label: string, date: Date): void => {
  trackEvent({
    event: 'calendar',
    eventCategory: 'calendar',
    eventAction: label,
    eventLabel:
      date.toDateString() === new Date().toDateString() ? 'current_date' : 'future_date',
  });
};

export const trackFormSubmissionErrors = (errors: FieldErrors): void => {
  trackEvent({
    event: 'formErrorException',
    eventCategory: 'form exception',
    eventAction: 'Error',
    eventLabel: Object.keys(errors).join(','),
  });
};

export const trackModalOpen = (label: string): void => {
  trackEvent({
    event: 'modalWindow',
    eventCategory: 'modal',
    eventAction: 'Appear',
    eventLabel: label,
  });
};

export const trackReCaptchaScore = (
  reCaptchaStatus: boolean,
  reCaptchaScore: number
): void => {
  trackEvent({
    event: 'recaptcha',
    reCaptchaAnswer: reCaptchaStatus,
    reCaptchaScore,
  });
};

export const trackAPIError = (error: Error): void => {
  if (isAxiosError(error) && error.response) {
    trackEvent({
      event: 'siteError',
      eventCategory: 'site error',
      eventAction: 'API error',
      eventLabel: `${error.response.status} - ${error.message}`,
      apiErrorCode: error.response.data?.Code,
      statusCode: error.response.status,
    });
  } else {
    trackEvent({
      event: 'siteError',
      eventCategory: 'site error',
      eventAction: 'General error',
      eventLabel: `${error?.message}`,
    });
  }
};

export const trackLinkClick = (pageTitle: PageTitle, linkText: string): void => {
  trackEvent({
    event: 'htmlLinkClick',
    eventCategory: 'plaintext link',
    eventAction: pageTitle,
    eventLabel: linkText,
  });
};

export const trackDownloadClick = (documentName: string, documentUrl: string): void => {
  trackEvent({
    event: 'download',
    eventCategory: 'document download',
    eventAction: documentName,
    eventLabel: documentUrl,
  });
};

export type RichTextClickTracker = (linkText: string, linkUrl: string) => void;

export const trackRichTextLinkClick = (pageTitle: PageTitle): RichTextClickTracker => (
  linkText,
  linkUrl
) =>
  // We do this so that pdf download links can be tracked with a different event
  // Note that this won't capture all download links but captures the current cases
  linkUrl.match(/.pdf$/)
    ? trackDownloadClick(linkText, linkUrl)
    : trackLinkClick(pageTitle, linkText);

export const trackSwitchPaymentClick = (
  pageTitle: PageTitle,
  action: 'annually' | 'monthly'
): void => {
  trackEvent({
    event: 'switchPayment',
    eventCategory: 'switch payment type',
    eventAction: pageTitle,
    eventLabel: action,
  });
};

export const trackAutoModalOpen = (descriptor: string): void => {
  trackEvent({
    event: 'popUpAppearance',
    eventCategory: 'pop up',
    eventAction: 'triggered',
    eventLabel: descriptor,
  });
};

export const trackFieldError = (fieldName: string, errorName: string): void => {
  trackEvent({
    event: 'fieldError',
    isException: false,
    eventCategory: 'form inline error',
    eventAction: 'Error',
    eventLabel: `${fieldName} - ${errorName}`,
  });
};

export const trackFooterLinkClick = (linkText: string, linkUrl: string): void => {
  trackEvent({
    event: 'footerClick',
    eventCategory: 'Footer Link',
    eventAction: 'Click',
    eventLabel: `${linkText} - ${linkUrl}`,
  });
};

export const trackCoverOptionsSelection = (coverOption: string): void => {
  trackEvent({
    event: 'coverOptions',
    eventCategory: 'Your quote',
    eventAction: 'Finding the right cover',
    eventLabel: coverOption,
  });
};

export const trackVetFeeLimitSelection = (limitSelected: string): void => {
  trackEvent({
    event: 'coverOptions',
    eventCategory: 'Your quote',
    eventAction: 'Vet fee limit',
    eventLabel: limitSelected,
  });
};

export const getReadableExcessAmount = (excess: VoluntaryExcessAmount): string => {
  if (!excess) return '£0';
  const excessWithoutLeadingZeroes = excess.match(/[1-9][0-9]*/)?.[0];
  return `£${excessWithoutLeadingZeroes}`;
};

export const getReadablePercentageExcessAmount = (
  excess: VoluntaryExcessPercentage
): string => {
  const excessWithoutLeadingZeroesOrLetters = excess
    ? excess.match(/[1-9][0-9]*/)?.[0]
    : '0';
  return `${excessWithoutLeadingZeroesOrLetters}%`;
};

export const trackExcessSelection = (
  petIndex: number,
  excessSelected: VoluntaryExcessAmount
): void => {
  const readableExcess = getReadableExcessAmount(excessSelected);
  trackEvent({
    event: 'excessClick',
    eventCategory: 'Your quote',
    eventAction: `Select your fixed excess - Pet ${petIndex}`,
    eventLabel: `${readableExcess}`,
  });
};

export const trackPercentageExcessSelection = (
  petIndex: number,
  excessSelected: VoluntaryExcessPercentage
): void => {
  const readableExcess = getReadablePercentageExcessAmount(excessSelected);
  trackEvent({
    event: 'additionalExcessClick',
    eventCategory: 'Your quote',
    eventAction: `Select your percentage excess - Pet ${petIndex}`,
    eventLabel: `${readableExcess}`,
  });
};

export const trackClubcardBackmatch = (): void => {
  trackEvent({
    event: 'clubcardBackmatch',
    eventCategory: 'Clubcard backmatch success',
    eventAction: 'Backmatch success',
    eventLabel: 'Clubcard backmatch success panel',
  });
};

export const trackClubcardLookup = (label: string): void => {
  trackEvent({
    event: 'clubcardLookup',
    eventCategory: 'Clubcard lookup success',
    eventAction: 'Lookup success',
    eventLabel: label,
  });
};
