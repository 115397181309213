import clubcardClient from 'apiHelpers/clubcardClient';
import { Quote } from 'apiHelpers/quote/quoteResponse';
import { ClubcardDetailsResponse } from './details';
import { ClubcardSearchQueryParams, ClubcardSearchResponse } from './search';

export type ClubcardData = {
  clubcardLookupResponse: ClubcardDetailsResponse | null;
  clubcardSearchResponse: ClubcardSearchResponse | null;
};

const mapQuoteToClubcardSearch = (quote: Quote): ClubcardSearchQueryParams => {
  const { firstName, lastName, address } = quote.customerInfo;
  return {
    firstName,
    lastName,
    flatName: address.flatName ?? undefined,
    flatNumber: address.flatNumber ?? undefined,
    houseName: address.houseName ?? undefined,
    houseNumber: address.houseNumber ?? undefined,
    street: address.street ?? undefined,
    postcode: address.postcode ?? undefined,
  };
};

const getClubcardDataFromQuote = async (quote: Quote): Promise<ClubcardData> => {
  const quoteClubcardNumber = quote.customerInfo.clubcardNumber;
  if (!quoteClubcardNumber) {
    return {
      clubcardLookupResponse: null,
      clubcardSearchResponse: null,
    };
  }
  const [search, lookup] = await Promise.allSettled([
    clubcardClient.search(mapQuoteToClubcardSearch(quote)),
    clubcardClient.details(quoteClubcardNumber),
  ]);
  return {
    clubcardSearchResponse: search.status === 'fulfilled' ? search.value : null,
    clubcardLookupResponse: lookup.status === 'fulfilled' ? lookup.value : null,
  };
};

export default getClubcardDataFromQuote;
