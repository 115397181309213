import { gender_F, gender_M, title_MR } from './referenceDataConstants';

/**
 * Returns gender from title:
 * gender_F (female) for title_MISS, title_MRS, title_MS
 * gender_M (male) for title_MR
 *
 * It assumes title is one the following: title_MISS, title_MRS, title_MS, title_MR
 */
export const getInferedGenderFromTitle = (title: string): string => {
  return title === title_MR ? gender_M : gender_F;
};
