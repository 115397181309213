import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  initialLookupValue,
  LookupStatus,
  LookupValue,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { FullAddress } from 'apiHelpers/address/address';
import { ClubcardDetailsResponse } from 'apiHelpers/clubcard/details';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { initialDateValue } from './shared/dateValue';

export const UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS';

export type AddressInfo = { id?: string; description: string };

type PostcodeLookup = LookupValue<string, AddressInfo[]>;

export type AddressDetails = {
  isManualAddress: boolean;
  usePreviousAddress: boolean;
  postcodeLookup: PostcodeLookup;
  address: FullAddress | undefined;
  flatNameOrNumber: string;
  houseNameOrNumber: string;
  street: string;
  town: string;
  county: string;
  postcode: string;
};

export type ClubcardSearch =
  | { status: 'NONE' }
  | { status: 'FAILURE' }
  | {
      status: 'SUCCESS';
      data: {
        clubcardNumber: string;
        isStaff: boolean;
      };
    };

export type ClubcardLookup = LookupValue<string, ClubcardDetailsResponse>;

type EmployeeNumber = {
  status: LookupStatus.Pending | LookupStatus.Success;
  number: string;
};

export type ClubcardDetails = {
  clubcardLookup: ClubcardLookup;
  clubcardSearch: ClubcardSearch;
  hasClubcard: string;
  clubCardApplied: string;
  hasColleagueClubcard: boolean;
  employeeNumber: EmployeeNumber;
  hasClubCardUpdatedInQS: boolean;
};

export type CustomerDetails = {
  customerFirstName: string;
  customerLastName: string;
  customerTelephone: string;
  customerEmail: string;
  customerDob: DateValue;
  customerTitle: string;
  customerGender: string;
  numberOfPetsInHousehold: number | undefined;
} & ClubcardDetails &
  AddressDetails;

export const initialPostcodeLookup: PostcodeLookup = initialLookupValue('');

const initialAddressDetails: AddressDetails = {
  isManualAddress: false,
  usePreviousAddress: false,
  postcodeLookup: initialPostcodeLookup,
  address: undefined,
  flatNameOrNumber: '',
  houseNameOrNumber: '',
  street: '',
  town: '',
  county: '',
  postcode: '',
};

export const initialClubcardLookup: ClubcardLookup = initialLookupValue('');

export const initialEmployeeNumber: EmployeeNumber = {
  status: LookupStatus.Pending,
  number: '',
};

export const initialCustomerDetails: CustomerDetails = {
  customerFirstName: '',
  customerLastName: '',
  customerTelephone: '',
  customerEmail: '',
  customerDob: initialDateValue,
  customerTitle: '',
  customerGender: '',
  numberOfPetsInHousehold: undefined,
  clubcardLookup: initialClubcardLookup,
  clubcardSearch: { status: 'NONE' },
  hasClubcard: '',
  clubCardApplied: '',
  hasColleagueClubcard: true,
  employeeNumber: initialEmployeeNumber,
  hasClubCardUpdatedInQS: false,
  ...initialAddressDetails,
};

export type UpdateCustomerDetailsAction = {
  type: typeof UPDATE_CUSTOMER_DETAILS;
  update: CustomerDetails;
};

export const useCustomerDetails = (): [
  CustomerDetails,
  (update: Partial<CustomerDetails>) => void
] => {
  const customerDetails = useSelector((state: RootState) => state.customerDetails);
  const dispatch = useDispatch();

  const updateCustomerDetails = useCallback(
    (update: Partial<CustomerDetails>): void => {
      dispatch({ type: UPDATE_CUSTOMER_DETAILS, update });
    },
    [dispatch]
  );

  return [customerDetails, updateCustomerDetails];
};
