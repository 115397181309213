import {
  getBundleFromQuote,
  getCoverLevelFromQuoteOptions,
  getSelectedBundleCovers,
} from 'apiHelpers/quote/bundleCoverMapping';
import { QuoteCustomerInfo, QuotePolicyInfo } from 'apiHelpers/quote/quoteRequest';
import { Bundle, Quote, QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { QuoteOptions, UPDATE_QUOTE_OPTIONS } from 'state/formData/quoteOptions';
import { PriceSummary } from 'state/quote/types';
import { ProductId } from './businessConstants';

export type CurrentQuote = {
  petInfos: QuoteResponsePetInfo[] | null;
  customerInfo: QuoteCustomerInfo | null;
  policyInfo: QuotePolicyInfo | null;
  quoteOptions: QuoteOptions;
  price: PriceSummary | null;
  productId: ProductId;
  selectedBundleCovers: string[];
};

const parsePricePerPet = (
  pricePerPet: number[]
): { total: number; perPet: number[] } => ({
  total: parseFloat(pricePerPet.reduce((total, price) => total + price).toFixed(2)),
  perPet: pricePerPet.map((price) => parseFloat(price.toFixed(2))),
});

export const getPricesFromBundle = (bundle: Bundle): PriceSummary => {
  const {
    annualPrice: { petPrices: annualPricePerPet },
    installments,
  } = bundle;
  const monthlyPricePerPet = installments[0].petPrices;

  return {
    annualPrice: parsePricePerPet(annualPricePerPet),
    monthlyPrice: parsePricePerPet(monthlyPricePerPet),
  };
};

const getPricesFromQuote = (
  quoteOptions: QuoteOptions,
  quote: Quote
): PriceSummary | null => {
  const coverLevel = getCoverLevelFromQuoteOptions(quoteOptions);

  const bundle = getBundleFromQuote(coverLevel, quote);
  return bundle ? getPricesFromBundle(bundle) : null;
};

const currentQuoteSelector = (state: RootState): CurrentQuote => {
  const { quote, quoteSummaryOptions } = state;

  return {
    petInfos: quote?.petInfos ?? null,
    customerInfo: quote?.customerInfo ?? null,
    policyInfo: quote?.policyInfo ?? null,
    quoteOptions: quoteSummaryOptions,
    price: quote ? getPricesFromQuote(quoteSummaryOptions, quote) : null,
    productId: quote?.productId ?? ProductId.DIRECT,
    selectedBundleCovers: quote ? getSelectedBundleCovers(quote) : [],
  };
};

/**
 * Provides the quote data and active quote options in an easily readable state
 */
export const useCurrentQuote = (): CurrentQuote =>
  useSelector(currentQuoteSelector, shallowEqual);

export const useUpdateQuoteOptions = (): ((update: Partial<QuoteOptions>) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (update: Partial<QuoteOptions>): void => {
      dispatch({ type: UPDATE_QUOTE_OPTIONS, update });
    },
    [dispatch]
  );
};
